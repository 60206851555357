import React,{useEffect, useState} from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { FaSpider, FaSpinner } from "react-icons/fa";
import { EditBankDetail } from '../../../Redux/Actions/PaymentActions';

const EditBankModel = (props) => {
    const[loader,setLoader]=useState(false)
    const dispatch=useDispatch()
    const [bankDetails, setBankDetails] = useState('')
   const handleChange=(e)=>{
    const {name,value,checked}=e.target
    if(name ==="is_primary"){
        setBankDetails({...bankDetails,[name]:checked ? 1:0})
    }
    else{
        setBankDetails({...bankDetails,[name]:value})
    }
   
   }
  
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
       
        dispatch(EditBankDetail(bankDetails,setLoader,props.handleClose1)) 
    }
   
    useEffect(()=>{
        if(props.edit){
            setBankDetails(props.edit)
           
        }
        
       },[props.edit])

  return (
    <>
            <Modal className="theme-modal link_submit_modal" show={props.show1} onHide={props.handleClose1} backdrop="static" centered>
                <Modal.Header closeButton style={{ borderBottom: "0" }}>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <h3 className="text-center">Please Edit Your Bank Details </h3>
                        {/* {/ <p className="text-center pt-2">Don't worry, you can upload in images, videos, audio any format</p> /} */}
                        <div className="modal-card mt-4 ">
                            <div className="mb-3">
                                <label for="accountHolderName" className="form-label">Account Holder Name</label>
                                <input type="text" className="form-control" id="accountHolderName" placeholder="John Doe" name="account_holder_name" value={bankDetails.account_holder_name} onChange={handleChange} required />
                            </div>

                            <div class="mb-3">
                                <label for="accountNumber" className="form-label">Account Number</label>
                                <input type="number" className="form-control" id="accountNumber" placeholder="1234567890" name="account_no" value={bankDetails.account_no} onChange={handleChange} required />
                            </div>
                            <div class="mb-3">
                                <label for="ifscCode" className="form-label">IFSC Code</label>
                                <input type="text" className="form-control" id="ifscCode" placeholder="IFSC0001234" name="ifsc_code" value={bankDetails.ifsc_code} onChange={handleChange} required />
                            </div>
                            <div class="mb-3">
                                <label for="bankName" className="form-label">Bank Name</label>
                                <input type="text" className="form-control" id="bankName" placeholder="ABC Bank" name="bank_name" value={bankDetails.bank_name} onChange={handleChange} required />
                            </div>
                            <div class="mb-3">
                                <label for="bankName" className="form-label">Branch  Name</label>
                                <input 
                                type="text" 
                                className="form-control" 
                                id="bankName" 
                                placeholder="ABC Bank" 
                                name="branch_name" 
                                value={bankDetails.branch_name} 
                                onChange={handleChange} 
                                required 
                                />
                            </div>
                            <div className="col-sm-6">
                                <div className="task-reference-block">
                                    <span className="custom-check">
                                        <input
                                            type="checkbox"
                                            name="is_primary"
                                            checked={+bankDetails.is_primary === 1 ? true : false}
                                            onChange={handleChange}
                                        />
                                        <span className="checkMark"></span>
                                    </span>
                                    <div className="task-reference-info">
                                        <h6>Primary Account</h6>

                                    </div>
                                </div>
                            </div>


                            <div className="link_btn text-end mt-5">
                                <button className='theme-btn cancelBtn'type="button" onClick={props.handleClose1}>Cancel</button>
                                <button className='theme-btn saveBtn' type="submit" style={{cursor:"pointer"}}>Update Details {loader ? <FaSpinner className="spin ms-1" /> : null}</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal >
        </>
  )
}

export default EditBankModel