import { ScrollPanel } from 'primereact/scrollpanel'
import React, { useState, useEffect } from 'react'
import user from '../../../images/user.png'
import { useDispatch, useSelector } from 'react-redux'
import { FetchTaskTaskerData } from '../../../Redux/Actions/TaskTaskerActions'
import { useNavigate } from 'react-router-dom'


const RecommendTasks = () => {
    const Taskdata = useSelector(state => state.TaskData.data)
    const [data, setData] = useState('')
const navigation=useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {

        dispatch(FetchTaskTaskerData());
    }, []);


    useEffect(() => {
        if (Taskdata) {
            setData(Taskdata)

        }


    }, [Taskdata])
    
    return (
        <>
            <div className="side_task_wrapper">
                <ScrollPanel className="custombar2 content-area" style={{ height: "100vh" }}>
                    <div className=" Recommend_tasks mt-0">
                        <h6 className=''>Recommend Tasks</h6>
                        <ScrollPanel className="custombar2 content_area_task">
                            <div className="Recommend_tasks_wrapper">
                                {data?.recommended_task?.length > 0 ? data?.recommended_task.map((curElem, i) => {
                                   
                                    return (
                                        <div className="Recommend_tasks_cont"  onClick={() => navigation("/task-details", { state: { "slug": curElem?.slug } })} key={i}>
                                            <div className="recom_img">
                                                <img src={curElem?.profile ? curElem?.profile : user} alt="" />
                                            </div>
                                            <div className="recom_para">
                                                <p>{curElem?.title}  </p>
                                                <span>{curElem?.created_at}</span>
                                            </div>
                                        </div>
                                    )

                                }) :<div  className='d-flex justify-content-center align-items-center'>No tasks found</div>}
                               
                            </div>
                        </ScrollPanel>
                    </div>
                    <div className="Recommend_tasks">
                        <h6 className=''>Bookmarked Tasks</h6>
                        <ScrollPanel className="custombar2 content_area_task">
                            <div className="Recommend_tasks_wrapper">
                               
                                {data?.saved_task?.length > 0 ? data?.saved_task.map((curElem, i) => {
                                  
                                    return (
                                        <div className="Recommend_tasks_cont" onClick={() => navigation("/task-details", { state: { "slug": curElem?.slug } })} key={i}>
                                            <div className="recom_img">
                                                <img src={curElem?.profile ? curElem?.profile : user} alt="" />
                                            </div>
                                            <div className="recom_para">
                                                <p>{curElem?.title}  </p>
                                                <span>{curElem?.created_at}</span>
                                            </div>
                                        </div>
                                    )

                                }) : <div  className='d-flex justify-content-center align-items-center'>No tasks found</div>}
                               
                            </div>
                        </ScrollPanel>
                    </div>
                    <div className="Recommend_tasks">
                        <h6 className=''>In Progress Tasks</h6>
                        <ScrollPanel className="custombar2 content_area_task">
                            <div className="Recommend_tasks_wrapper">
                            {data?.ongoing_task?.length > 0 ? data?.ongoing_task.map((curElem, i) => {
                                  
                                  return (
                                      <div className="Recommend_tasks_cont"  onClick={() => navigation("/task-details", { state: { "slug": curElem?.slug } })} key={i}>
                                          <div className="recom_img">
                                              <img src={curElem?.profile ? curElem?.profile : user} alt="" />
                                          </div>
                                          <div className="recom_para">
                                              <p>{curElem?.title}  </p>
                                              <span>{curElem?.created_at}</span>
                                          </div>
                                      </div>
                                  )

                              }) : <div  className='d-flex justify-content-center align-items-center'>No tasks found</div>}
                            </div>
                        </ScrollPanel>
                    </div>
                </ScrollPanel >
            </div>
        </>
    )
}

export default RecommendTasks
