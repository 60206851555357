import React from "react";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { IoClose, IoChevronBack, IoCheckmarkOutline } from "react-icons/io5";

function VerificationSixthStep() {
  return (
    <>
      <div className="verification-wrap">
        <div className="verification-outer">
          <div className="verification-title">
            <button className="nextBtn"><IoChevronBack /></button>
            <h2>Remove phone number</h2>
            <button className="nextBtn"><IoClose /></button>
          </div>
          <div className="verification-main">
            <div className="text-center"><img src={require("../../../images/icon/trash.svg").default} alt="" /></div>
            <h4 className="text-center pt-4">This may turn 2-step verification off.</h4>

            <div className="text-center mt-4">
              <button className="theme-btn red large">Remove</button>
            </div>

          </div>
        </div>
      </div>

      <div className="float-message">
        <div className="float-message-in"><IoCheckmarkOutline /> Phone number has been removed</div>
      </div>
    </>
  )
}

export default VerificationSixthStep;