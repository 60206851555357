import React, { useState } from "react";

import { IoCalendarClearOutline } from "react-icons/io5";
import { FiDownload, FiChevronLeft, FiChevronRight } from "react-icons/fi";

import { PiUserCircleLight } from "react-icons/pi";
import { GrLocation } from "react-icons/gr";
import { HiOutlineEnvelope } from "react-icons/hi2";

import AllEarnings from "./AllEarnings";
import PayoutEarnings from "./PayoutEarnings";

function EarningsTabs(props) {
// console.log(props)
  return (
    <>

     <AllEarnings data={props?.data?.earnings?props.data.earnings:""} loader={props.loader}/>
     <PayoutEarnings data={props?.data?.history?props.data.history:""} loader={props.loader}/>
     

      
    </>
  )
}

export default EarningsTabs;