import { ScrollPanel } from 'primereact/scrollpanel'
import React, { useState, useEffect } from 'react'
import { Dropdown } from 'react-bootstrap'
import { IoSearchOutline } from 'react-icons/io5'
import { PiClockUser } from 'react-icons/pi'
import picPro from '../../../images/pp.png';
import pinset from '../../../images/icon-2/pinset.svg';
import CheckCircle from '../../../images/icon-2/CheckCircle.svg';
import BackCircle from '../../../images/icon-2/BackCircle.svg';
import XCircle from '../../../images/icon-2/XCircle.svg';
import FilePdf from '../../../images/icon-2/FilePdf.svg';
import Pagination from '../../Common/Pagination'
import { FaSpinner } from 'react-icons/fa'
import { json } from 'react-router-dom'
import AdminBanModel from '../AdminModal/AdminBanModel'
import AdminActiveModel from '../AdminModal/AdminActiveModel'
import { useDispatch } from 'react-redux'
import { setAlert } from '../../../Redux/Actions/AlertActions'
import { adminSubmissionApprove, adminSubmissionReject } from '../../../Redux/Actions/AdminManagerNavAction'


const AdminTaskSubmissions = (props) => {
    const [filteredData, setFilteredData] = useState([])
    const [data, setData] = useState([])

    const [loader, setLoader] = useState({
        active: false,
        banAccount: false
    })
    const [searchInput, setSearchInput] = useState('')
    const [sortOrder, setSortOrder] = useState('asc');
    const [submissionId, setSubmissionId] = useState('')
    const [reason, setReason] = useState('')
    const [showModel, setShowModel] = useState(false)
    const [showModel1, setShowModel1] = useState(false)
    const handleShow = () => setShowModel(true)
    const handleClose = () => setShowModel(false)
    const handleShow1 = () => setShowModel1(true)
    const handleClose1 = () => setShowModel1(false)
    const dispatch = useDispatch()

    const handleSort = () => {
        const sortedData = [...filteredData].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.id - b.id;
            } else {
                return b.id - a.id;
            }
        });

        setFilteredData(sortedData);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    }
    useEffect(() => {
        if (props.data) {
            setData(props.data)
        }
        else {
            setData([])
        }
    }, [props.data])
    useEffect(() => {

        if (!searchInput) {

            setFilteredData(data)

        } else {
            const filterSerach = data.filter(item =>

                item.title.toLowerCase().includes(searchInput.toLowerCase()) ||
                item.name.toLowerCase().includes(searchInput.toLowerCase())

            );
            setFilteredData(filterSerach)
        }

    }, [data, searchInput])
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 6
    });
    const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;
    const currentTodo = filteredData.slice(indexofFirstTodo, indexofLastTodo);
    const handleApprove = () => {
        setLoader({
            ...loader,
            banAccount: true

        })
        let data = {
            id: submissionId
        }

        dispatch(adminSubmissionApprove(data, loader, setLoader, handleClose1, props.fetchdata))
    }
    const handleReject = () => {
        setLoader({
            ...loader,
            active: true

        })
        let data = {
            id: submissionId,
            "reason": reason
        }

        if (reason === '') {
            setLoader({
                ...loader,
                active: false

            })
            dispatch(setAlert("Reason  is requried", "danger"))
        } else {
            dispatch(adminSubmissionReject(data, loader, setLoader, handleClose, props.fetchdata))
        }
    }

    return (
        <>
            {
                props.loader.fetch ? <div className="d-flex align-items-center justify-content-center mt-4"><FaSpinner className="spin ms-1" style={{ fontSize: "40px" }} /></div> :

                    <ScrollPanel className="custombar2 content-area pt-4">

                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className='ms-3 mb-4'>
                                        <div className="table-bar mb-4">
                                            <div className="table-bar-left">
                                                <div className="table-bar-icon">
                                                    <div className="table-nav">
                                                        <div className="profile-menu">

                                                        </div>
                                                        {/* <div className="profile-menu">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="successN" className="drop-btn">
                                                                    <img src={require("../../../images/icon/filter.svg").default} alt="" />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <p style={{ fontSize: 15, fontWeight: 500 }}> Lorem ipsum </p>
                                                                    <li className="drop-list">
                                                                        <span className="custom-check">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="agentType"
                                                                            />
                                                                            <span className="checkMark"></span>
                                                                        </span>
                                                                        dolor sit amet
                                                                    </li>
                                                                    <li className="drop-list">
                                                                        <span className="custom-check">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="agentType"
                                                                            />
                                                                            <span className="checkMark"></span>
                                                                        </span>
                                                                        dolor sit amet.
                                                                    </li>


                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div> */}
                                                    </div>


                                                    <ul>
                                                        <li title="Sort by Date" onClick={handleSort}>
                                                            <img src={require("../../../images/icon/sort.svg").default} alt="" />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="table-bar-right">
                                                <div className="header-search">
                                                    <input
                                                        type="text"
                                                        placeholder="Search"
                                                        name="searchInput" value={searchInput} onChange={(e) => setSearchInput(e.target.value)}
                                                    />
                                                    <span className="left-icon"><IoSearchOutline
                                                    /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="task_applications_wrap mb-5">
                                        <div className="row g-4">
                                            {currentTodo.length > 0 ? currentTodo.map((curElem, i) => {
                                                const linkData = JSON.parse(curElem?.link) || []
                                                const files = JSON.parse(curElem?.files) || []
                                                return (
                                                    <div className="col-lg-4 col-md-6 col-12" key={i}>
                                                        <div className='account-block' style={{ height: "100%" }}>
                                                            <div className="Submissions_details">
                                                                <div className="application_date mb-0">
                                                                    <PiClockUser />
                                                                    <span>{curElem.createdat}</span>
                                                                </div>

                                                                <div className="application_img">
                                                                    <img src={curElem.profile} alt={data.name} />
                                                                </div>
                                                            </div>

                                                            <div className="application_para">
                                                                <h3>{curElem.name}</h3>
                                                                <p>{curElem.title || "No title available"}</p>
                                                                <p>{curElem.text || "No description available"}</p>
                                                                <div className="sub_links">

                                                                    {linkData.length > 0 ? (
                                                                        linkData.map((url, Index) => (
                                                                            <React.Fragment key={Index}>

                                                                                <div className='d-flex'>
                                                                                    <span style={{ fontWeight: 700, color: "#000" }}> Link {Index + 1} : </span>
                                                                                    <a
                                                                                        href={url}
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                    >
                                                                                        {url}
                                                                                    </a>
                                                                                </div>


                                                                            </React.Fragment>
                                                                        ))
                                                                    ) : (
                                                                        <p className='mt-2 ms-3'>No links available</p>
                                                                    )}
                                                                </div>
                                                                <div className="sub_links mt-0">

                                                                    {files.length > 0 ? (
                                                                        files.map((urls, Index) => (
                                                                            <React.Fragment key={Index}>

                                                                                <div className='d-flex'>
                                                                                    <span style={{ fontWeight: 700, color: "#000" }}> File {Index + 1} : </span>
                                                                                    <a
                                                                                        href={urls}
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                    >
                                                                                        {urls}
                                                                                    </a>
                                                                                </div>


                                                                            </React.Fragment>
                                                                        ))
                                                                    ) : (
                                                                        <p className='mt-2 ms-3'>No Files available</p>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="Submissions_info">

                                                                {curElem.is_completed === "0" ? <div className="total_sub_action">
                                                                    <img src={CheckCircle} alt="Check Circle" onClick={() => {
                                                                        handleShow1()
                                                                        setSubmissionId(curElem.id)
                                                                    }} />
                                                                    <img src={XCircle} alt="X Circle" onClick={() => {
                                                                        handleShow()
                                                                        setSubmissionId(curElem.id)
                                                                    }} />
                                                                    <img src={BackCircle} alt="Back Circle" />
                                                                </div>
                                                                    : curElem.is_completed === "1" ? <div className="total_sub_action" style={{ background: "#17B169", padding: "5px 10px", borderRadius: "4px", color: "#fff" }}>Approved</div> : <div className="total_sub_action" style={{ background: "#c04000", padding: "5px 10px", borderRadius: "4px", color: "#fff" }}>Rejected</div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }) : <div className="d-flex align-items-center justify-content-center"> {data.length === 0 ? "No Task Found" : ""}</div>}
                                            <Pagination
                                                listData={filteredData}
                                                pagination={pagination}
                                                setPagination={setPagination}
                                                currentTodo={currentTodo}
                                                listArr={props.data}
                                                loader={props.loader.fetch}
                                            />
                                            <AdminBanModel show={showModel} handleClose={handleClose} setReason={setReason} reason={reason} loader={loader} handleBanAccount={handleReject} type="submission" />
                                            <AdminActiveModel show={showModel1} handleClose={handleClose1} loader={loader} handleBanAccount={handleApprove} type="submission" />


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ScrollPanel >
            }
        </>
    )
}

export default AdminTaskSubmissions
