import React from 'react'

const EmailAndPhone = () => {
  return (
<>
<div className='account-block phone-emails mb-3'>
    <div className="mb-3">
  <label for="" className="form-label">Email</label>
  <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com"/>
</div>
<div class="modal-button-bottom" style={{background:"unset"}}><button class="theme-btn px-4" type="submit">Update</button></div>
</div>
<div className='account-block phone-emails'>
<div className="mb-3">
  <label for="" className="form-label">Mobile No.</label>
  <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Enter Your Mobile Number"/>
</div>
<div class="modal-button-bottom" style={{background:"unset"}}><button class="theme-btn px-4" type="submit">Update</button></div>

</div>
</>
  )
}

export default EmailAndPhone
