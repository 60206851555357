import { combineReducers } from "redux";
import { AlertReducer } from "./AlertReducer";
import { AuthReducer } from "./AuthReducer";
import { RebrandingReducer } from "./RebrandingReducer";
import { HelpReducer } from "./HelpReducer";
import { SocialReducer } from "./SocialReducer";
import TaskCreatorReducer from "./TaskCreatorReducer";
import { PaymentReducer } from "./PaymentReducer";
import { TaskTaskerReducer } from "./TaskTaskerReducer";


const appReducer = combineReducers({
    alert: AlertReducer,
    auth: AuthReducer,
    rebrand: RebrandingReducer,
    help: HelpReducer,
    social: SocialReducer,
    TaskCreator: TaskCreatorReducer,
    BankDetails:PaymentReducer,
   TaskData: TaskTaskerReducer
})

const rootReducers = (state, action) => {
    if (action.type === 'LOGOUT') {
        localStorage.clear();
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducers;