import React, { useState } from 'react'
import { GoBriefcase } from 'react-icons/go'
import { IoPersonCircleOutline } from 'react-icons/io5'
import Navbar from "../Common/Navbar";
import { FaSpider, FaSpinner } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const AccountSelector = () => {
  const [loader, setLoader] = useState(false)
  const navigation=useNavigate();
  const[userType,setUserType]=useState("Tasker")
const handleType=(type)=>{
   
    setUserType(type)
}
    const handleSubmit=(e)=>{
        e.preventDefault()
      
        navigation("/sign-up",{state:{"userType":userType}})
    }
    return (
        <>
            <section className="login_wrapper">
                <div className="container-fluid h-100">
                    <div className="col-12 h-100">
                        <div className="row ">
                            <Navbar />
                            <div className="sign_from_main">
                                <div className="col-12">
                                    <div className="sign_form">
                                        <form className="sign_form_top" onSubmit={handleSubmit}>

                                            <div className="sign_form_content">

                                                <span className="mt-2">Choose Account Type</span>
                                                <div className="sign-input-forget mt-1 text-center">
                                                    <p> <span>If you need more info, please check out </span> Help Page</p>
                                                </div>



                                                <div className="sign_Account_wrap Account_select">

                                                    <div className="sign_Account mb-4" onClick={()=>handleType("Tasker")} style={{border:userType==="Tasker"?"1px solid #000":""}}>

                                                        <div className="sign_acc_icon">
                                                            <IoPersonCircleOutline style={{ fontSize: '30px' }} />
                                                        </div>

                                                        <div className="sign_acc_name">
                                                            <span>Tasker Account</span>
                                                            <p>Looking to earn money, tasks are waiting.</p>
                                                        </div>
                                                        <div class="form-check ">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />

                                                        </div>
                                                    </div>

                                                    <div className="sign_Account " onClick={()=>handleType("Creator")} style={{border:userType==="Creator"?"1px solid #000":""}}>
                                                        <div className="sign_acc_icon">
                                                            <GoBriefcase />
                                                        </div>

                                                        <div className="sign_acc_name">
                                                            <span>Creator Account</span>
                                                            <p>Create account to get your task done.</p>
                                                        </div>
                                                        <div class="form-check">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />

                                                        </div>
                                                    </div>

                                                </div>


                                                <div className="sign-input-wrap">
                                                    <button type="submit" className="login-btn btn ">Sign Up {loader ? <FaSpinner className="spin ms-1" /> : null}</button>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AccountSelector
