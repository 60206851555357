import { ScrollPanel } from 'primereact/scrollpanel'
import React from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import { IoSearchOutline } from 'react-icons/io5'
import calendar from '../../../../images/icon/CalendarBlank.svg'
import { RiEdit2Line } from "react-icons/ri";
import { useLocation } from 'react-router-dom'

const AdminTaskDetails = () => {
   
    return (
        <>
            <div className='ps-3 pe-3 '>
                <div className="table-bar">
                    <div className="table-bar-left">
                        <div className="table-bar-icon">
                            <div className="table-nav">
                                <div className="profile-menu">

                                </div>
                                <div className="profile-menu">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="successN" className="drop-btn">
                                            <img src={require("../../../../images/icon/filter.svg").default} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <p style={{ fontSize: 15, fontWeight: 500 }}> Lorem ipsum </p>
                                            <li className="drop-list">
                                                <span className="custom-check">
                                                    <input
                                                        type="checkbox"
                                                        name="agentType"
                                                    />
                                                    <span className="checkMark"></span>
                                                </span>
                                                dolor sit amet
                                            </li>
                                            <li className="drop-list">
                                                <span className="custom-check">
                                                    <input
                                                        type="checkbox"
                                                        name="agentType"
                                                    />
                                                    <span className="checkMark"></span>
                                                </span>
                                                dolor sit amet.
                                            </li>


                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>


                            <ul>
                                <li title="Sort by Date">
                                    <img src={require("../../../../images/icon/sort.svg").default} alt="" />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="table-bar-right">
                        <div className="header-search">
                            <input
                                type="text"
                                placeholder="Search"
                            />
                            <span className="left-icon"><IoSearchOutline
                            /></span>
                        </div>
                    </div>
                </div>
            </div>

            <ScrollPanel className="custombar2 content-area pt-2">

                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='account-block ms-2 me-2 '>
                                <h3>Task Type <RiEdit2Line className='ms-2' /></h3>
                                <div className="row g-3">
                                    <div className="col-lg-6 col-12">
                                        <div className="submission_wrap mt-3 py-4 ">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <h3>Limited Submission</h3>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />

                                                </div>
                                            </div>
                                            <div className='new_task_para mt-2'>
                                                <p>Limited Submission
                                                    There are no limits to the number of times you can participate in this task, allowing you to contribute multiple times</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <div className="submission_wrap mt-3 py-4 ">
                                            <div className='d-flex justify-content-between'>
                                                <h3>Unlimited Submission</h3>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />

                                                </div>
                                            </div>
                                            <div className='new_task_para mt-2'>
                                                <p>Limited Submission
                                                    There are no limits to the number of times you can participate in this task, allowing you to contribute multiple times</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">

                                    <div className="submission_wrap mt-3 py-4 ">
                                        <span>Task Category</span>
                                        <div style={{ marginLeft: "-10px" }}>
                                            <Form.Select aria-label="Task selection">
                                                <option value="" disabled selected>Select a task category</option>
                                                <option value="survey-research">Survey and Market Research Tasks</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </Form.Select>
                                        </div>
                                    </div>
                                    <div className="submission_wrap mt-3  ">
                                        <span>Task Title</span>
                                        <h3 className='mt-2' style={{ fontWeight: "500" }}>Participate in a Short Survey</h3>
                                    </div>
                                    <div className="submission_wrap mt-3 pt-4 ">
                                        <span>Task Description</span>
                                        <h3 className='mt-2' style={{ fontWeight: "500" }}>We invite you to take part in a brief survey designed to gather insights on [specific topic]. Your valuable input will help us improve our products and services.</h3>
                                        <div className='mt-2 mb-2' >
                                            <p>
                                                <b> Task Type:</b>  Survey Participation

                                            </p>
                                            <p>
                                                <b>  Estimated Time to Complete:</b> 5-10 minutes

                                            </p>
                                            <p>
                                                <b> Reward:</b> $X.XX per completed survey

                                            </p>

                                        </div>
                                        <div className='mt-2 mb-2' >
                                            <h3>Instructions:</h3>
                                            <ol>
                                                <li>
                                                    Click on the survey link provided below.
                                                </li>
                                                <li>
                                                    Answer all the questions honestly and to the best of your ability.
                                                </li>
                                                <li>
                                                    Submit your responses by clicking the "Submit" button at the end of the survey.
                                                </li>
                                                <li>
                                                    You may complete this survey multiple times, but only one submission per person will be considered.
                                                </li>
                                                <li>
                                                    Click on the survey link provided below.
                                                </li>
                                            </ol>
                                            <h3 className='mt-3'>Requirements:</h3>
                                            <ul>
                                                <li>Must have access to the internet.</li>
                                                <li>Must be able to read and understand [language].</li>
                                                <li>Must provide genuine and thoughtful responses.</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="submission_wrap mt-3 py-4 ">
                                        <span>Experience Required</span>
                                        <div style={{ marginLeft: "-10px" }}>
                                            <Form.Select aria-label="Default select example">
                                                <option>Entry Level</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </Form.Select>
                                        </div>
                                    </div>
                                    <div className="submission_wrap mt-3  ">
                                        <span>Maximum Allowed Submission</span>
                                        <h3 className='mt-2' style={{ fontWeight: "500" }}>100</h3>
                                    </div>
                                    <div className="submission_wrap mt-3  ">
                                        <span>Price ( Per submission )</span>
                                        <h3 className='mt-2' style={{ fontWeight: "500" }}>$25</h3>
                                    </div>
                                    <div className="submission_wrap mt-3  ">
                                        <span>Due Date</span>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <h3 className='mt-2' style={{ fontWeight: "500" }}>12/7/2024</h3>
                                            <img src={calendar} alt="" />
                                        </div>
                                    </div>

                                    <div className="new_task_create ">
                                        <button className='theme-btn light'>Cancel</button>
                                        <button className='theme-btn'>Publish Task</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ScrollPanel>
        </>
    )
}

export default AdminTaskDetails
