import React, { useState } from 'react';

import SidePanelTaskCreator from '../../Common/SidePanelTaskCreator';
import DashboardHeader from "../../Common/DashboardHeader";
import DashboardFooter from "../../Common/DashboardFooter";
import { ScrollPanel } from 'primereact/scrollpanel';

import PersonalDetailsTabs from "./PersonalDetailsTabs";
import NotificationTabs from "./NotificationTabs";

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import SecurityTabs from './SecurityTabs';
import EmailAndPhone from './EmailAndPhone';
import PasswordUpdate from './PasswordUpdate';
import KYC from './KYC';

function ProfileSettingsTaskCreator() {

   const [isActive, setIsActive] = useState(false);
   const toggleClass = () => {
      setIsActive(!isActive);
   };

   const [isActive2, setIsActive2] = useState(false);
   const toggleNotification = () => {
      setIsActive2(!isActive2);
   };

   return (
      <>
         <div className={isActive ? 'dashboard dashboard-modified activeSidePanel' : 'dashboard dashboard-modified'}>
            <SidePanelTaskCreator />
            <div className="dashboard-in">
               <DashboardHeader toggleSidebar={toggleClass} notificationToggle={toggleNotification} title="My Account" />
               <ScrollPanel className="custombar2 content-area">
                  <div className="content-area-in tab-style">
                     <Tab.Container id="left-tabs-example" defaultActiveKey="tab-1">
                        <div className="tab-bar">
                           <Nav variant="pillsb">
                              <Nav.Item><Nav.Link eventKey="tab-1">Personal Details</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-2">Email & Phone Number</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-5">Password</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-6">KYC</Nav.Link></Nav.Item>
                              {/* <Nav.Item><Nav.Link eventKey="tab-2">Payment Methods</Nav.Link></Nav.Item> */}
                              <Nav.Item><Nav.Link eventKey="tab-3">Notifications</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-4">Security</Nav.Link></Nav.Item>
                           </Nav>

                           {/* <div className="tab-bar-right">
                     <ul>
                        <li><Link>+ Add User</Link></li>
                        <li><Link>Add Target</Link></li>
                        <li>
                           <span className="profile-menu">
                              <Dropdown>
                                 <Dropdown.Toggle variant="" id="dropdown-basic">
                                    <BiDotsHorizontalRounded />
                                 </Dropdown.Toggle>
                                 <Dropdown.Menu>
                                    <Link>Action</Link>
                                    <Link>Another action</Link>
                                    <Link>Something else</Link>
                                 </Dropdown.Menu>
                              </Dropdown>
                           </span>
                        </li>
                     </ul>
                  </div> */}

                        </div>
                        <Tab.Content>
                           <Tab.Pane eventKey="tab-1">
                              <PersonalDetailsTabs />
                           </Tab.Pane>
                           {/* <Tab.Pane eventKey="tab-2">
                              <PaymentMethodsTabs />
                           </Tab.Pane> */}
                              <Tab.Pane eventKey="tab-2">
                              <EmailAndPhone />
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-3">
                              <NotificationTabs />
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-4">
                              <SecurityTabs />
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-5">
                              <PasswordUpdate />
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-6">
                              <KYC />
                           </Tab.Pane>
                        </Tab.Content>
                     </Tab.Container>
                  </div>
               </ScrollPanel>
               <DashboardFooter />
            </div>

         </div>

      </>
   )
}
export default ProfileSettingsTaskCreator;