import { ScrollPanel } from 'primereact/scrollpanel'
import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { IoIosStarOutline } from 'react-icons/io'
import { IoSearchOutline } from 'react-icons/io5'
import { BsDot } from "react-icons/bs";
import Small from '../../../../images/icon-2/Small.png';
import { FaAngleRight } from "react-icons/fa";

const CreatorTaskReviews = () => {
    return (
        <>
            <ScrollPanel className="custombar2 content-area pt-4">

                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12'>

                            <div className="task_applications_wrap">
                                <div className="account-block">
                                    <div className="creator_wrap_reviews">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="creator_review_head">
                                                    <h3 className='mb-4'>Average rating: <IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline />5.0 <BsDot style={{ position: "static" }} /> <span>7 reviews</span></h3>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="review_rating_wrap">
                                                    <div className="review_rating">
                                                        <p>Requirements Clarity</p>
                                                        <span></span>
                                                        <p>5.0</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="review_rating_wrap">
                                                    <div className="review_rating">
                                                        <p>Timely Feedback</p>
                                                        <span></span>
                                                        <p>5.0</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="review_rating_wrap">
                                                    <div className="review_rating">
                                                        <p>Communication</p>
                                                        <span></span>
                                                        <p>5.0</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="review_rating_wrap">
                                                    <div className="review_rating">
                                                        <p>Cooperation</p>
                                                        <span></span>
                                                        <p>5.0</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="review_rating_wrap">
                                                    <div className="review_rating">
                                                        <p>Clear Instructions</p>
                                                        <span></span>
                                                        <p>5.0</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="review_rating_wrap">
                                                    <div className="review_rating">
                                                        <p>Professionalism</p>
                                                        <span></span>
                                                        <p>5.0</p>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-12">
                                                <div className="reviews_profiles_wrap">
                                                    <div className="reviews_profiles">
                                                        <div className="reviews_profile_img">
                                                            <img src={Small} alt="" />
                                                        </div>
                                                        <div className="review_details">
                                                            <h3>Abhi Divedi</h3>
                                                            <div className='mt-2'>
                                                                <p>Leave a Comment on Insta Video  </p>
                                                                <span>Feb 25, 2023</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="review_details_para">
                                                        <p>It was fun working with Mehedi. Excellent quality of work.</p>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="reviews_profiles_wrap">
                                                    <div className="reviews_profiles">
                                                        <div className="reviews_profile_img">
                                                            <img src={Small} alt="" />
                                                        </div>
                                                        <div className="review_details">
                                                            <h3>Abhi Divedi</h3>
                                                            <div className='mt-2'>
                                                                <p>Leave a Comment on Insta Video  </p>
                                                                <span>Feb 25, 2023</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="review_details_para">
                                                        <p>Nice person to work with!</p>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="reviews_profiles_wrap">
                                                    <div className="reviews_profiles">
                                                        <div className="reviews_profile_img">
                                                            <img src={Small} alt="" />
                                                        </div>
                                                        <div className="review_details">
                                                            <h3>Abhi Divedi</h3>
                                                            <div className='mt-2'>
                                                                <p>Leave a Comment on Insta Video  </p>
                                                                <span>Feb 25, 2023</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="review_details_para">
                                                        <p>Thank you for the comprehensive and well-structured technical documentation. The clarity and precision of the information are outstanding, making it easy for our team to understand and implement..</p>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="reviews_profiles_wrap">
                                                    <div className="reviews_profiles">
                                                        <div className="reviews_profile_img">
                                                            <img src={Small} alt="" />
                                                        </div>
                                                        <div className="review_details">
                                                            <h3>Abhi Divedi</h3>
                                                            <div className='mt-2'>
                                                                <p>Leave a Comment on Insta Video  </p>
                                                                <span>Feb 25, 2023</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="review_details_para">
                                                        <p className='mb-4'>You have a great eye for detail, and your creativity shines through in every element. Excellent work !
                                                        ...</p>
                                                        <div className='review_show_all'><a href="#">Show more</a><span><FaAngleRight /></span></div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 text-center">
                                                <button className='theme-btn light review_btn mt-5 border-black bg-light' >Show all 12 reviews</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </ScrollPanel >
        </>
    )
}

export default CreatorTaskReviews
