import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import pdf from '../../../images/icon/FilePdf.svg';
import jpg from '../../../images/icon/FileJpg.svg';
import xml from '../../../images/icon/FileXls.svg';
import { FaSpinner } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import axios from 'axios';
import PendingFilesModel from './PendingFilesModel';
import { baseURL } from '../../../Global/Global';

const UploadFilesModal = (props) => {
    const token = useSelector(state => state.auth.token);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loader, setLoader] = useState({
        upload: false,
        submit: false
    });
    const [file, setFile] = useState([]);

    const handledchange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const allowedTypes = ['application/pdf', 'image/jpeg', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

        const filteredFiles = selectedFiles.filter(file => allowedTypes.includes(file.type));
        setFile(filteredFiles);
    };
   

    const handleSubmitflies = async () => {
        setLoader({ ...loader, submit: true });

if(file.length===0){
   dispatch(setAlert("Please select file","danger"))
   setLoader({ ...loader, submit: false });
}  else{


        if (file.length > 0) {
            const formData = new FormData();
            
            for (let i = 0; i < file.length; i++) {
                formData.append(`image[${i}]`, file[i]);
            }

            formData.append("slug", props?.slugId);
            formData.append("type", "file");

            try {
                const response = await axios.post(
                    `${baseURL}task-file-submission`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${token}`,
                        }
                    }
                );

                if (response.data.status) {
                    dispatch(setAlert(response.data.message, "success"));
                   handleShow()
                   props.handleClose()
                   setFile([])
                } else {
                    dispatch(setAlert(response.data.message, "danger"));
                }
            } catch (error) {
                console.error('Error uploading files:', error);
                dispatch(setAlert('Error uploading files', 'danger'));
            } finally {
                setLoader({ ...loader, submit: false });
            }
        }
    }
    };
   
    const bytesToMB = (bytes) => {
        return (bytes / (1024 * 1024)).toFixed(2);
    };
    return (
        <>
            <Modal className="theme-modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>
                <Modal.Header closeButton style={{ borderBottom: "0" }}></Modal.Header>
                <Modal.Body>
                    <div className="upload_file_modal">
                        <h3 className="text-center">Please upload your files for final review</h3>
                        <p className="text-center pt-2">Be careful, you can’t alter your submission without approval</p>
                        <div className="modal-card mt-4">
                            <h6>Uploaded Files</h6>
                            <div className="upload_file_wrap">
                                <ul>
                                {
                                        loader.fetch ? <><FaSpinner className="spin custom-spin ms-1 d-flex  justify-content-center align-items-center" /></> : file.length > 0 ? file.map((curElem, i) => {
                                           
                                            return (
                                                <li>
                                                    <div className="file_formate_img p">
                                                        <img src={curElem?.type ==="application/pdf" ? pdf : curElem?.type === "image/jpeg" ? jpg : xml} alt="" />
                                                    </div>
                                                    <div className="file_para">
                                                        <p>
                                                            {curElem.name}
                                                        </p>
                                                        <span>{bytesToMB(curElem.size)} MB</span>
                                                    </div>
                                                </li>
                                            )
                                        }) : <>
                                            <div className='d-flex justify-content-center align-items-center'>No files have been uploaded yet.</div>
                                        </>
                                    }

                                </ul>
                                <div className="account-block">
                                    <span>Drop files here or upload files</span>
                                    <button className='theme-btn light'>Upload {loader.upload ? <FaSpinner className="spin ms-1" /> : null}</button>
                                    <input 
                                        type="file" 
                                        multiple 
                                        style={{ opacity: 0 }} 
                                        onChange={handledchange}
                                        accept=".pdf,.jpg,.xls,.xlsx"
                                    />
                                </div>
                                <div className="upload_btn text-center mt-4">
                                    <button className='theme-btn purple' onClick={handleSubmitflies}>
                                        Submit Files {loader.submit ? <FaSpinner className="spin ms-1" /> : null}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <PendingFilesModel show={show} handleClose={handleClose} slug={props?.slugId}/>
        </>
    );
};

export default UploadFilesModal;
