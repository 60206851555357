import React, { useEffect, useState } from 'react'
import { Nav, Tab } from 'react-bootstrap';
import SidePanel from '../../Common/SidePanel';
import DashboardHeader from '../../Common/DashboardHeader';
import TitleBar from '../../Common/TitleBar';
import AvailableTasks2 from './TaskDetails/TaskModuleComponent/AvailableTasks2';
import AvailableTasks from './TaskDetails/TaskModuleComponent/AvailableTasks';
import { useDispatch, useSelector } from 'react-redux';
import { FetchTaskTaskerData } from '../../../Redux/Actions/TaskTaskerActions';
import DashboardFooter from '../../Common/DashboardFooter';

const Task = () => {
    const [isActive, setIsActive] = useState(true);
    const toggleClass = () => {
        setIsActive(!isActive);
    };
    // const Taskdata = useSelector(state => state.TaskData.data)
    const [data, setData] = useState('')
    const [loader, setLoader] = useState({
        fetch: true
    })
    const dispatch = useDispatch()
    const FetchData = () => {

        dispatch(FetchTaskTaskerData(setData, setLoader, loader));
    }
    useEffect(() => {
        FetchData()
    }, []);



    return (
        <>
            <TitleBar title="Tasks" />

            <div className="dashboard Tasker_main_container container-fluid overflow-hidden">
                <div className="row">
                    <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>
                        <SidePanel />
                    </div>
                    <div className={` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
                        <div className="dashboard-in">
                            <DashboardHeader toggleSidebar={toggleClass} title="Tasks" />
                            <div>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <div className="row" >
                                        <div className="col-12">
                                            <Nav variant="pills" style={{ padding: "28px 28px 0px" }} >
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first" > Available Tasks</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second">Recommended Tasks</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="third">Ongoing Tasks</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="fourth">Completed Tasks</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="fifth">Saved Tasks </Nav.Link>
                                                </Nav.Item>

                                            </Nav>


                                            <div className="setting_right_side_wrap fix-pad">
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="first">
                                                        <AvailableTasks datatable={data.avaiable_task} loader={loader} saveTask={data?.user_bookmark_task} FetchData={FetchData} />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="second">
                                                        <AvailableTasks datatable={data.recommended_task} loader={loader} saveTask={data?.user_bookmark_task} FetchData={FetchData} />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="third">
                                                        <AvailableTasks datatable={data.ongoing_task} loader={loader} saveTask={data?.user_bookmark_task} FetchData={FetchData} />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="fourth">
                                                        <AvailableTasks datatable={data.completed_task} type="completed" loader={loader} saveTask={data?.user_bookmark_task} FetchData={FetchData} />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="fifth">
                                                        <AvailableTasks datatable={data.saved_task} type="save" loader={loader} saveTask={data?.user_bookmark_task} FetchData={FetchData} />
                                                    </Tab.Pane>


                                                </Tab.Content>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Container >
                            </div>
                        </div>
                        <DashboardFooter />

                    </div>
                </div >
            </div >

        </>
    )
}

export default Task
