import React from 'react'
import { Modal } from "react-bootstrap";
import { ScrollPanel } from "primereact/scrollpanel";
import { FaSpider, FaSpinner } from "react-icons/fa";

const TaskPreferenceEditModal = (props) => {
   
  return (
<Modal className="modalRights theme-modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>
<Modal.Header closeButton style={{ borderBottom: "0" }}>
</Modal.Header>
            <Modal.Body className='pt-0'>
                <div className='modalTitle ps-0 pb-2 mb-4' style={{height:"unset"}}>
                    <h6>Edit Category</h6>
                </div>

                <div>
                    <div className="input-wrap mb-3 alt">
                        <label className="label mb-2" htmlFor="">Name</label>
                        <input className="input" type="text" placeholder="Enter Value" name="name" id="" value={props.edit} onChange={(e)=>props.setEdit(e.target.value)} />
                    </div>
                    <div className="modal-button-bottom">
                        <button className="theme-btn bdr"  type="button" onClick={props.handleClose}>Cancel</button>
                        <button className="theme-btn" type="button" onClick={props.handleEditSubmit}>Save{props.loader.edit?<FaSpinner className="spin" />:""}</button>
                    </div>

                </div>

            </Modal.Body>
        </Modal>
  )
}

export default TaskPreferenceEditModal
