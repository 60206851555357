import React, { useState } from 'react';
import { FiDownload } from "react-icons/fi";

import { Dropdown } from 'react-bootstrap';
import { IoCalendarClearOutline, IoSearchOutline } from "react-icons/io5";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { Link } from 'react-router-dom';

function InvoiceHistory() {

   return (
      <>
      <div className="table-bar mt-0">
            <div className="table-bar-left">
              <div className="table-bar-icon">
                <div className="table-nav">
                  <div className="profile-menu">
                      <Dropdown>
                          <Dropdown.Toggle variant="successN" className="drop-btn">
                            <img src={require("../../../images/icon/plus.svg").default} alt="" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <li className="drop-list">
                                <span className="custom-check">
                                  <input type="checkbox" name="" />
                                  <span className="checkMark"></span>
                                </span>
                                User
                            </li>
                            <li className="drop-list">
                                <span className="custom-check">
                                  <input type="checkbox" name="" />
                                  <span className="checkMark"></span>
                                </span>
                                Email
                            </li>
                            <li className="drop-list">
                                <span className="custom-check">
                                  <input type="checkbox" name="" />
                                  <span className="checkMark"></span>
                                </span>
                                Phone Number
                            </li>
                          </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    <div className="profile-menu">
                      <Dropdown>
                          <Dropdown.Toggle variant="successN" className="drop-btn">
                            <img src={require("../../../images/icon/filter.svg").default} alt="" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <li className="drop-list">
                                <span className="custom-check">
                                  <input type="checkbox" />
                                  <span className="checkMark"></span>
                                </span>
                                Inbound
                            </li>
                          </Dropdown.Menu>
                      </Dropdown>
                    </div>
                </div>
                <ul>
                    <li title="Sort by Date">
                      <img src={require("../../../images/icon/sort.svg").default} alt="" />
                    </li>
                </ul>
              </div>
            </div>
            <div className="table-bar-right">
                <div className="header-search">
                  <input type="text" placeholder="Search"/>
                  <span className="left-icon"><IoSearchOutline /></span>
                </div>
            </div>
          </div>

        <div className='table-responsive mt-3'>
            <table className='table theme-table'>
                <tr>
                    <th>
                        <span className='custom-check'>
                            <input type="checkbox" name="" id="" />
                            <span className='checkMark'></span>
                        </span>
                    </th>
                    <th>Tansactoion ID</th>
                    <th>Description</th>
                    <th>Date</th>
                    <th>Charged Amount</th>
                    <th>Status</th>
                    <th>Invoice</th>
                    <th className='text-end'></th>
                </tr>
                <tr>
                    <td>
                        <span className='custom-check'>
                            <input type="checkbox" name="" id="" />
                            <span className='checkMark'></span>
                        </span>
                    </td>
                    <td>#INV9801</td>
                    <td>Amount Charged $1200 from xxxx-4614</td>
                    <td>Feb 25, 2023</td>
                    <td>$1200</td>
                    <td><span className='table-stat inactive'>Pending</span></td>
                    <td><FiDownload /> PDF</td>
                    <td className='text-end'>
                    <span className="profile-menu">
                        <Dropdown>
                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                <BiDotsHorizontalRounded />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Link>Action</Link>
                                <Link>Another action</Link>
                                <Link>Something else</Link>
                            </Dropdown.Menu>
                        </Dropdown>
                    </span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span className='custom-check'>
                            <input type="checkbox" name="" id="" />
                            <span className='checkMark'></span>
                        </span>
                    </td>
                    <td>#INV9801</td>
                    <td>Amount Charged $1200 from xxxx-4614</td>
                    <td>Feb 25, 2023</td>
                    <td>$1200</td>
                    <td><span className='table-stat active'>Complete</span></td>
                    <td><FiDownload /> PDF</td>
                    <td className='text-end'>
                    <span className="profile-menu">
                        <Dropdown>
                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                <BiDotsHorizontalRounded />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Link>Action</Link>
                                <Link>Another action</Link>
                                <Link>Something else</Link>
                            </Dropdown.Menu>
                        </Dropdown>
                    </span>
                    </td>
                </tr>
            </table>
        </div>
      </>
   )
}
export default InvoiceHistory;