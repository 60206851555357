import React, { useEffect, useState } from "react";

import { GrLocation } from "react-icons/gr";
import { IoCalendarClearOutline, IoSearchOutline } from "react-icons/io5";
import { BiDotsHorizontalRounded } from "react-icons/bi";

import Modal from 'react-bootstrap/Modal';
import { MultiSelect } from 'primereact/multiselect';
import { ScrollPanel } from 'primereact/scrollpanel';

import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import AddNewUsersModal from "../AdminModal/AddNewUsersModal";
import { useDispatch } from "react-redux";
import { fetchAdminlistAction } from "../../../Redux/Actions/AdminSettingsActions";
import Pagination from "../../Common/Pagination";
import { FaSpinner } from "react-icons/fa";

function AdminUsersTabs() {
  const [loader, setLoader] = useState({
    fetch: false,
    addUser: false
  })
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch()
  const [searchInput, setSearchInput] = useState('')

  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [sortOrder, setSortOrder] = useState('asc');

  const handlesort = () => {
    const sortedData = [...filteredData].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.id - b.id;
      } else {
        return b.id - a.id;
      }
    });

    setFilteredData(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  }
  const fetchData = () => {
    setLoader({
      ...loader,
      fetch: true
    })
    dispatch(fetchAdminlistAction(setData, setLoader, loader))
  }
  useEffect(() => {
    fetchData()

  }, [])
  useEffect(() => {

    if (!searchInput) {

      setFilteredData(data)

    } else {
      const filterSerach = data.filter(item =>
        item.name.toLowerCase().includes(searchInput.toLowerCase()) ||
        item.email.toLowerCase().includes(searchInput.toLowerCase()) ||
        item.id.toString().includes(searchInput)
      );
      setFilteredData(filterSerach)
    }
  }, [searchInput, data])


  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalItemOnPage: 15
  });
  const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
  const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;
  const currentTodo = filteredData.slice(indexofFirstTodo, indexofLastTodo);




  return (
    <>

      <div className="tabBtn">
        <button className="theme-btn" onClick={handleShow}>Add New User</button>
      </div>

      <div className="table-bar mt-0">
        <div className="table-bar-left">
          <div className="table-bar-icon">
            <div className="table-nav">
              {/* <div className="profile-menu">
                <Dropdown>
                  <Dropdown.Toggle variant="successN" className="drop-btn">
                    <img src={require("../../../images/icon/plus.svg").default} alt="" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <li className="drop-list">
                      <span className="custom-check">
                        <input type="checkbox" name="" />
                        <span className="checkMark"></span>
                      </span>
                      User
                    </li>
                    <li className="drop-list">
                      <span className="custom-check">
                        <input type="checkbox" name="" />
                        <span className="checkMark"></span>
                      </span>
                      Email
                    </li>
                    <li className="drop-list">
                      <span className="custom-check">
                        <input type="checkbox" name="" />
                        <span className="checkMark"></span>
                      </span>
                      Phone Number
                    </li>
                  </Dropdown.Menu>
                </Dropdown>
              </div> */}

              {/* <div className="profile-menu">
                <Dropdown>
                  <Dropdown.Toggle variant="successN" className="drop-btn">
                    <img src={require("../../../images/icon/filter.svg").default} alt="" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <li className="drop-list">
                      <span className="custom-check">
                        <input type="checkbox" />
                        <span className="checkMark"></span>
                      </span>
                      Inbound
                    </li>
                  </Dropdown.Menu>
                </Dropdown>
              </div> */}
            </div>
            <ul>
              <li title="Sort by Id" onClick={handlesort}>
                <img src={require("../../../images/icon/sort.svg").default} alt="" />
              </li>
            </ul>
          </div>
        </div>
        <div className="table-bar-right">
          <div className="header-search">
            <input type="text" placeholder="Search" name="searchInput" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
            <span className="left-icon"><IoSearchOutline /></span>
          </div>
        </div>
      </div>
      {
        loader.fetch ? <div className="d-flex align-items-center justify-content-center pt-3"><FaSpinner className="spin" style={{ fontSize: "40px" }} /></div> :
          <div className="table-responsive mt-3">
            <table className="table theme-table">
              <thead>
                <tr>
                  {/* <th>
        <span className="custom-check">
          <input type="checkbox" />
          <span className="checkMark"></span>
        </span>
      </th> */}
                  <th>User</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>User Role</th>
                  <th>Date Joined</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              {

                currentTodo.length > 0 ? currentTodo.map((curElem, i) => {

                  return (
                    <tr key={i}>
                      {/* <td>
        <span className="custom-check">
          <input type="checkbox" />
          <span className="checkMark"></span>
        </span>
      </td> */}
                      <td data-label="User"><span className="userImg"><img src={curElem.profile} alt="" /></span>{curElem.name}</td>
                      <td data-label="Email">{curElem.email}</td>
                      <td data-label="Phone Number">{curElem.mobileNumber}</td>
                      <td data-label="User Role" className="text-capitalize">{curElem.role}</td>
                      <td data-label="Date Joined"><IoCalendarClearOutline /> {curElem.created}</td>
                      <td data-label="Status">{curElem.status === "1" ? <span className="table-stat active">Active</span> : curElem.status === "2" ? <span className="table-stat banned">Banned</span> : <span className="table-stat inactive">Inactive</span>}</td>
                      <td>
                        <span className="profile-menu">
                          <Dropdown>
                            <Dropdown.Toggle variant="" id="dropdown-basic">
                              <BiDotsHorizontalRounded />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Link>Action</Link>
                              <Link>Another action</Link>
                              <Link>Something else</Link>
                            </Dropdown.Menu>
                          </Dropdown>
                        </span>
                      </td>
                    </tr>
                  )
                })
                  : <div className="d-flex align-items-center justify-content-center text-center ">{data.length===0?<td colSpan="7" >No user data</td>:""}</div>
              }




            </table>
         <div className="my-5">
         <Pagination

listData={filteredData}
pagination={pagination}
setPagination={setPagination}
currentTodo={currentTodo}
listArr={data}
loader={loader.fetch}
/>
         </div>
          </div>
      }

      <AddNewUsersModal show={show} handleClose={handleClose} loader={loader} setLoader={setLoader} fetchData={fetchData} />
    </>
  )
}

export default AdminUsersTabs;