import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Link from '../../../images/icon/link.png'
import upload from "../../../images/icon-2/LinkUpload.svg"
import edit from "../../../images/icon-2/LinkEdit.svg"
import UploadFilesModal from './UploadFilesModal'
import ApplicationSubmitModal from './ApplicationSubmitModal'
import LinkSubmissionModel from './LinkSubmissionModel'
const TaskSubmitModal = (props) => {
    const [show, setShow] = useState(false);
    const [showLink, setShowLink] = useState(false);
    const [showManually, setShowManually] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleShowLink = () => setShowLink(true);
    const handleCloseLink = () => setShowLink(false);
    const handleShowManually = () => setShowManually(true);
    const handleCloseManully = () => setShowManually(false);
    return (
        <>

            <Modal className="theme-modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>
                <Modal.Header closeButton style={{ borderBottom: "0" }}>
                </Modal.Header>
                <Modal.Body>
                    <div className="submit_modal">
                        <h3 className="text-center">How do you want to submit your task?</h3>
                        <p className="text-center pt-2">Be careful; you can’t alter your submission without approval.</p>
                        <div className="modal-card mt-4 bg-transparent">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 mb-4 mb-lg-0">
                                    <div className="account-block mt-2" onClick={() => {
                                        handleShowLink()
                                        props.handleClose()
                                    }} style={{ cursor: "pointer" }}>
                                        <div className="submit_icon" >
                                            <img src={Link} alt="" />
                                        </div>
                                        <div className="submit_head">
                                            <h6>Link Submission</h6>
                                        </div>
                                        <div className="submit_para">
                                            <p>
                                            For tasks  like blogs, social media updates,data entry,surveys etc.
                                            </p>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 mb-4 mb-lg-0">
                                    <div className="account-block mt-2" onClick={() => {
                                        handleShow()
                                        props.handleClose()
                                    }} style={{ cursor: "pointer" }} >
                                        <div className="submit_icon">
                                            <img src={upload} alt="" />
                                        </div>
                                        <div className="submit_head">
                                            <h6>Upload File</h6>
                                        </div>
                                        <div className="submit_para">
                                            <p>
                                            For documents, images, videos, audio,files,modeling,rendering etc.
                                            </p>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 mb-4 mb-lg-0">
                                    <div className="account-block mt-2" onClick={() => {
                                        handleShowManually()
                                        props.handleClose()

                                    }} style={{ cursor: "pointer" }}>
                                        <div className="submit_icon">
                                            <img src={edit} alt="" />
                                        </div>
                                        <div className="submit_head">
                                            <h6>Type Manually</h6>
                                        </div>
                                        <div className="submit_para">
                                            <p>
                                            For written content, essays, reports,blogposts etc.
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
            <UploadFilesModal
                show={show}
                handleClose={handleClose}
                slugId={props.slugId}
            />
            <ApplicationSubmitModal
                show={showManually}
                handleClose={handleCloseManully}
                slugId={props.slugId}
            />
            <LinkSubmissionModel
                show={showLink}
                handleClose={handleCloseLink}
                slugId={props.slugId}
            />
        </>
    )
}

export default TaskSubmitModal
