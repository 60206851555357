import React, { useState, useEffect } from 'react';

import SidePanel from "../../Common/SidePanel";
import DashboardHeader from "../../Common/DashboardHeader";
import DashboardFooter from "../../Common/DashboardFooter";
import { ScrollPanel } from 'primereact/scrollpanel';


import { MultiSelect } from 'primereact/multiselect';


import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import SidePanelAdmin from '../../Common/SidePanelAdmin';
import { GrLocation } from "react-icons/gr";
import { IoCalendarClearOutline, IoSearchOutline } from "react-icons/io5";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { FetchAdminCreatorUser } from '../../../Redux/Actions/AdminManagerUserAction';
import { useDispatch } from 'react-redux';
import { FaSpider, FaSpinner } from "react-icons/fa";
import Pagination from '../../Common/Pagination';


const AdminCreatorList = () => {

  const dispatch = useDispatch()
  const [searchInput, setSearchInput] = useState('')
  const [loader, setLoader] = useState({
    fetch: true
  })
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [sortOrder, setSortOrder] = useState('asc');
  const handlesort = () => {
    const sortedData = [...filteredData].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.id - b.id;
      } else {
        return b.id - a.id;
      }
    });

    setFilteredData(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  }

  useEffect(() => {
    setLoader({
      fetch: true
    })

    dispatch(FetchAdminCreatorUser(loader, setLoader, setData))
  }, [])

  useEffect(() => {

    if (!searchInput) {

      setFilteredData(data)

    } else {
      const filterSerach = data.filter(item =>
        item.name.toLowerCase().includes(searchInput.toLowerCase()) ||
        item.email.toLowerCase().includes(searchInput.toLowerCase()) ||
        item.id.toString().includes(searchInput)
      );
      setFilteredData(filterSerach)
    }
  }, [searchInput, data])
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalItemOnPage: 15
  });
  const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
  const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;
  const currentTodo = filteredData.slice(indexofFirstTodo, indexofLastTodo);




  return (
    <>
      <div className="table-bar mt-0">
        <div className="table-bar-left">
          <div className="table-bar-icon">
            <div className="table-nav">

            </div>
            <ul>
              <li title="Sort by Id" onClick={handlesort}>
                <img src={require("../../../images/icon/sort.svg").default} alt="" />
              </li>
            </ul>
          </div>
        </div>
        <div className="table-bar-right">
          <div className="header-search">
            <input type="text" placeholder="Search" name="searchInput" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
            <span className="left-icon"><IoSearchOutline /></span>
          </div>
        </div>
      </div>
      {
        loader.fetch ? <div className='d-flex justify-content-center align-items-center pt-3'><FaSpinner className="spin ms-1" style={{ fontSize: "40px" }} /></div> :

          <div className="table-responsive mt-3">
            <table className="table theme-table">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>User Type</th>
                  <th>Date Joined</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              {currentTodo.length > 0 ? currentTodo.map((curElem, i) => {
                return (
                  <tr key={i}>

                    <td data-label="User"><span className="userImg"><img src={curElem.profile ? curElem?.profile : "https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg"} alt="" /></span>{curElem.name}</td>
                    <td data-label="Email">{curElem.email}</td>
                    <td data-label="Phone Number">{curElem.mobileNumber}</td>
                    <td data-label="User Type">{curElem.role}</td>
                    <td data-label="Date Joined"><IoCalendarClearOutline /> {curElem.created}</td>
                    {curElem.status === "1" ? <td><span className="table-stat active">Active</span></td> : curElem.status === "0" ? <td><span className="table-stat inactive">Inactive</span></td> : <td><span className="table-stat banned">Banned</span></td>}

                    <td data-label="Status">
                      <span className="profile-menu">
                        <Dropdown>
                          <Dropdown.Toggle variant="" id="dropdown-basic">
                            <BiDotsHorizontalRounded />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Link to="/admin/tasker/profile" state={{ slug: curElem.id }}>View</Link>

                          </Dropdown.Menu>
                        </Dropdown>
                      </span>
                    </td>
                  </tr>

                )
              }) : <div className="d-flex justify-content-center align-items-center mt-3"> {data.length===0?"No User Data":""}</div>}


            </table>
          </div>


      }

      <div className='d-flex justify-content-end my-3 me-4'>
        <Pagination

          listData={filteredData}
          pagination={pagination}
          setPagination={setPagination}
          currentTodo={currentTodo}
          listArr={data}
          loader={loader.fetch}
        />
      </div>

    </>
  )
}

export default AdminCreatorList