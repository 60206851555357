import React from "react";
import { ScrollPanel } from 'primereact/scrollpanel';

function NotificationTabs() {
    return(
        <>
          <div className="account-block mb-3 mb-lg-5">
            <h6>Notification Settings</h6>
            <div className="block-white mt-4">
              <h6>Notifications</h6>
              <div className="notification-cardN">
                <div className="notification-card-single">
                    <div className="notification-card-txt">
                        <h6>Web push notifications</h6>
                        <p>Receive push notifications on your web app.</p>
                    </div>
                    <span className="custom-switch">
                        <input type="checkbox" />
                        <span className="checkMark"></span>
                    </span>
                </div>

                <div className="notification-card-single">
                    <div className="notification-card-txt">
                        <h6>Email notifications</h6>
                        <p>Receive email updates.</p>
                    </div>
                    <span className="custom-switch">
                        <input type="checkbox" />
                        <span className="checkMark"></span>
                    </span>
                </div>

                <div className="notification-card-single">
                    <div className="notification-card-txt">
                        <h6>SMS notifications</h6>
                        <p>Receive updates in your mobile number.</p>
                    </div>
                    <span className="custom-switch">
                        <input type="checkbox" />
                        <span className="checkMark"></span>
                    </span>
                </div>

                <div className="notification-card-single">
                    <div className="notification-card-txt">
                        <h6>Whatsapp notifications</h6>
                        <p>Receive updates in your whatsapp number.</p>
                    </div>
                    <span className="custom-switch">
                        <input type="checkbox" />
                        <span className="checkMark"></span>
                    </span>
                </div>
              </div>
            </div>
          </div>
        </>
    )
}

export default NotificationTabs;