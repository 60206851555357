import React, { useState, useEffect } from 'react'
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const Pagination = ({ listData, pagination, setPagination, currentTodo, loader, listArr }) => {
    const [pageNumber, setPageNumber] = useState([])
    const handleClick = (num) => {
        if (num === "..." || num === pagination.currentPage) {

        } else {
            setPagination({
                ...pagination,
                currentPage: num,
            });
        }
    };
    const handleBackward = (num) => {
        if (pageNumber[0] < num) {
            setPagination({
                ...pagination,
                currentPage: num - 1,
            });
        }
    };
    const handleForward = (num) => {
        if (pageNumber[pageNumber.length - 1] > num) {
            setPagination({
                ...pagination,
                currentPage: num + 1,
            });
        }
    };
    useEffect(() => {
        if (listData.length >= 0) {
            let maxLength = listData.length;
            let data = [];
            if (maxLength > pagination.totalItemOnPage) {
                let val = maxLength / pagination.totalItemOnPage;
                if (val > parseInt(val)) {
                    val = val + 1;
                }
                val = parseInt(val)
                for (let i = 1; i <= val; i++) {
                    if (val > 5) {
                        if (i === 1 && pagination.currentPage !== i) {
                            data.push(i)
                        }
                        if (pagination.currentPage === i) {
                            if (i - 1 > 1) {
                                if (i - 2 !== 1) {
                                    data.push("...")
                                }
                                data.push(i - 1)
                            }
                            data.push(i)
                            if (i + 1 < val) {
                                data.push(i + 1)
                                if (i + 2 !== val) {
                                    data.push("...")
                                }
                            }
                        }
                        if (i === +val && pagination.currentPage !== i) {
                            data.push(i)
                        }
                    } else {
                        data.push(i);
                    }
                }
                setPageNumber(data);
            } else {
                setPageNumber([1]);
            }
        }
    }, [listData, pagination])
    return (
        <>
            {loader === true ? "" :
                listArr.length === 0 ? "" :
                    currentTodo.length !== 0 ? ""
                        :
                        <div className="text-center mt-4 text-dark" >
                            No Data Found
                        </div>
            }
            <div className="table-paging">
                <ul>
                    <li
                        onClick={() => handleBackward(pagination.currentPage)}
                    >
                        <FiChevronLeft
                        // style={{ color: pageNumber.length <= 1 ? "gray" : "" }}
                        />
                    </li>

                    {pageNumber.length > 0 ?
                        pageNumber.map((curElem, index) => {
                            return (
                                <li
                                    key={index}
                                    id={curElem}
                                    onClick={() => handleClick(curElem)}
                                    className={curElem === pagination.currentPage ? "active" : ""}
                                >
                                    {curElem}
                                </li>
                            );
                        })
                        : ""}
                    <li
                        onClick={() => handleForward(pagination.currentPage)}
                    >
                        <FiChevronRight
                        // style={{ color: pageNumber.length <= 1 ? "gray" : "white" }}
                        />
                    </li>
                </ul>
            </div></>
    )
}

export default Pagination