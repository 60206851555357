import React from 'react'
import { FiUpload } from 'react-icons/fi'
import { FaCloudUploadAlt } from "react-icons/fa";

const KYC = () => {
    return (
        <div>
            <div>

                <div className="submission_wrap submission_bg  py-4">

                    <div className="row align-items-center">
                        <div className="col-lg-6">
                        <h3 style={{fontSize:"18px"}}>Pan card</h3>
                            <div className=" create_upload mt-3  ">
                            <FaCloudUploadAlt
                            style={{ fontSize: "50px", color:"#c7006d" }} />
                                <span className='mb-2' style={{fontWeight:"500", fontSize:"16px"}}>Choose a file or drag & drop it here</span>
                                <span style={{color:"#A9ACB4"}}>JPEG, PNG, PDG and MP4 formats, up to 50MB</span>
                                <input type="file" />

                            </div>
                        </div>
                        <div className="col-lg-6">
                        <h3 style={{fontSize:"18px"}}>Aadhar card</h3>
                            <div className=" create_upload mt-3  ">
                            <FaCloudUploadAlt
                            style={{ fontSize: "50px", color:"#c7006d" }} />
                                <span className='mb-2' style={{fontWeight:"500", fontSize:"16px"}}>Choose a file or drag & drop it here</span>
                                <span style={{color:"#A9ACB4"}}>JPEG, PNG, PDG and MP4 formats, up to 50MB</span>
                                <input type="file" />

                            </div>
                        </div>
                        {/* <div className="col-2">
                            <button className='theme-btn' type='submit'>
                                Add
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KYC
