import React, { useEffect, useState } from "react";
import { ScrollPanel } from 'primereact/scrollpanel';
import { FaSpinner } from "react-icons/fa";
import Pagination from "../../Common/Pagination";
import { ProgressBar } from 'primereact/progressbar';
function ReviewsReceivedTabs(props) {
    const [reviewData, setReviewData] = useState([])

    useEffect(() => {
        if (props.data) {
            setReviewData(props?.data)
        }
        else {
            setReviewData([])
        }
    }, [props.data])

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 15
    });
    const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;
    const currentTodo = reviewData.slice(indexofFirstTodo, indexofLastTodo);






    return (
        <>

            <div className="account-block ex">
                <h6 className="d-flex align-items-center pb-3"><img src={require("../../../images/icon/star2.svg").default} alt="" /> <span className="ps-2">5.0 - 7 reviews</span></h6>


                <div className="review-list mt-2">
                    <ul>
                        {
                            currentTodo?.length > 0 ? currentTodo?.map((curElem, i) => {
                                return (
                                    <div className="border-bottoms" key={i}>
                                        <li>
                                            <div className="review-list-single">
                                                <div className="review-list-single-top">
                                                    <div className="review-list-single-img"><img src={curElem?.profile ? curElem?.profile : "https://images.pexels.com/photos/2787341/pexels-photo-2787341.jpeg"} alt="" /></div>
                                                    <div className="review-list-single-txt">
                                                        <h4>{curElem.name}</h4>
                                                        <h6>{curElem.created_at}</h6>
                                                    </div>
                                                </div>
                                                <p className="pt-3">{curElem.review}</p>
                                            </div>

                                        </li>
                                        <div className="rev-card mb-3">
                                            <div className="row">
                                                <div className="col-lg-6 col-12">
                                                    <div className="rev-card-single">
                                                        <h6>Availability</h6>
                                                        <div className="rev-card-stat">
                                                            <div className="rev-card-percentage">

                                                                <ProgressBar value={20 * curElem.availability} style={{ height: "20px" }} />
                                                            </div>
                                                            <div>{curElem.availability}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <div className="rev-card-single">
                                                        <h6>Cooperation</h6>
                                                        <div className="rev-card-stat">
                                                            <div className="rev-card-percentage">
                                                                <ProgressBar value={20 * curElem.cooperation} style={{ height: "20px" }} />
                                                            </div>
                                                            <div>{curElem.cooperation}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <div className="rev-card-single">
                                                        <h6>Communication</h6>
                                                        <div className="rev-card-stat">
                                                            <div className="rev-card-percentage">
                                                                <ProgressBar value={20 * curElem.communication} style={{ height: "20px" }} />
                                                            </div>
                                                            <div>{curElem.communication}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <div className="rev-card-single">
                                                        <h6>Quality of work</h6>
                                                        <div className="rev-card-stat">
                                                            <div className="rev-card-percentage">
                                                                <ProgressBar value={20 * curElem.work_quality} style={{ height: "20px" }} />
                                                            </div>
                                                            <div>{curElem.work_quality}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <div className="rev-card-single">
                                                        <h6>Skills</h6>
                                                        <div className="rev-card-stat">
                                                            <div className="rev-card-percentage">
                                                                <ProgressBar value={20 * curElem.skillset} style={{ height: "20px" }} />
                                                            </div>
                                                            <div>{curElem.skillset}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <div className="rev-card-single">
                                                        <h6>Adhere to deadline</h6>
                                                        <div className="rev-card-stat">
                                                            <div className="rev-card-percentage">
                                                                <ProgressBar value={20 * curElem.deadline} style={{ height: "20px" }} />
                                                            </div>
                                                            <div>{curElem.deadline}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                )
                            }) : <div className="d-flex justify-content-center align-items-center m-auto">No Review Received</div>
                        }


                    </ul>
                </div>
                <Pagination

                    listData={reviewData}
                    pagination={pagination}
                    setPagination={setPagination}
                    currentTodo={currentTodo}
                    listArr={reviewData}
                    loader={props.loader.fetch}
                />
                {/* <div className="text-center mt-4">
                    <button className="theme-btn white">Show all 12 reviews</button>
                </div> */}

            </div>


        </>
    )
}

export default ReviewsReceivedTabs;