import React, { useEffect, useRef, useState } from 'react';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
let iti = null
const PhoneNumber = (props) => {
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef && inputRef.current) {
            iti = intlTelInput(inputRef.current, {
                initialCountry: "in",
                utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.1.1/js/utils.js",
            });
            const countries = iti.countries.map(curElem => ({
                name: curElem.name,
                code: curElem.iso2.toUpperCase(),
                phoneCode: `+${curElem.dialCode}`
            }))
            props.setCountryInput(countries)

            return () => {
                if (iti) {
                    iti.destroy();
                }
            }
        }
    }, [inputRef]);


    return (
        <div className='d-none'>
            <select ref={inputRef} className='d-none'></select>
        </div>
    );
};

export default PhoneNumber;
