import React from 'react'
import { FiUpload } from "react-icons/fi";

const CreateUpload = (props) => {
    return (
        // <div className="row">
        //     <div className="col-10">
        //         <div className="submission_wrap create_upload mt-3 py-4 ">
        //             <FiUpload style={{ fontSize: "22px" }} />
        //             <span className='mb-2'>Upload Assets</span>
        //             <input type="file" />

        //         </div>
        //     </div>
        //     <div className="col-2">
        //         <button className='theme-btn' type='submit' disabled={!props.editing} style={!props.editing ? { color: "#fff", background: "gray" } : {}}>
        //             Add
        //         </button>
        //     </div>
        // </div>



        <div className="submission_wrap submission_bg mt-3 py-4">
            <span className='mb-2'>Assets Name</span>
            <input
                type="text"
                name="assets_name"
                value={props.data.assets_name}
                placeholder='Enter name'
                disabled={!props.editing}
                onChange={props.handleChange}

                style={{ border: "none", fontWeight: "500", width: "100%" }}
                required
            />
            <div className="row align-items-center">
                <div className="col-10">
                    <div className=" create_upload mt-3  ">
                        <FiUpload style={{ fontSize: "22px" }} />
                        <span className='mb-2'>Upload Assets</span>
                        <input type="file" />

                    </div>
                </div>
                <div className="col-2">
                    <button className='theme-btn' type='submit' disabled={!props.editing} style={!props.editing ? { color: "#fff", background: "gray", } : { minWidth: "50%" }}>
                        Add
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CreateUpload
