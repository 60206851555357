import { produce } from "immer"
const initalState = {
    data: {
        category: "1",
        experience: "2yr",
        title: "Create a task",
        description: "We Are creating the task",
        max_allowed_submission: "0",
        price: "10",
        type: "",
        due_date: "2024-08-30"
    }
}

const TaskCreatorReducer = (state = initalState, action) => {
    switch (action.type) {
        case "ADD_AGENT_DATA":
            return produce((state), draft => {
                let obj = { ...action.payload }
                obj.workflow = []
                draft.data = obj
            })

        case "ON_CHANGE_AGENT":
            return produce(state, (draft) => {
                let { name, value } = action.payload
                draft.data = {
                    ...draft.data,
                    [name]: value
                }
            })

        case "ON_CHANGE_AGENT_WORKFLOW":
            return produce(state, (draft) => {
                let { name, value, index } = action.payload
                if (name === "automationType") {
                    draft.data.workflow[index] = {
                        automationType: value
                    }
                } else {
                    draft.data.workflow[index] = {
                        ...draft.data.workflow[index],
                        [name]: value
                    }
                }
            })

        case "ON_ADD_WORKFLOW":
            return produce(state, (draft) => {
                let obj = {
                    automationType: ""
                }
                draft.data.workflow.unshift(obj)

            })

        case "ON_REMOVE_WORKFLOW":
            return produce(state, (draft) => {
                draft.data.workflow.splice(action.payload, 1)
            })

        case "ON_CHANGE_AGENT_NAME":
            return produce(state, (draft) => {
                draft.data.name = action.payload
            })
        default:
            return state
    }
}

export default TaskCreatorReducer