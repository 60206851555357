import React from 'react'

const PasswordUpdate = () => {
  return (
<>
<div className='account-block phone-emails mb-3'>
    <div className="mb-3">
        <label htmlFor="currentPassword" className="form-label">Current Password</label>
        <input type="password" className="form-control" id="currentPassword" placeholder="Enter current password"/>
    </div>
    
    <div className="mb-3">
        <label htmlFor="newPassword" className="form-label">New Password</label>
        <input type="password" className="form-control" id="newPassword" placeholder="Enter new password"/>
    </div>
    
    <div className="mb-3">
        <label htmlFor="confirmPassword" className="form-label">Confirm New Password</label>
        <input type="password" className="form-control" id="confirmPassword" placeholder="Confirm new password"/>
    </div>
    
    <div className="modal-button-bottom" style={{background: "unset"}}>
        <button className="theme-btn px-4" type="submit">Update Password</button>
    </div>
</div>

</>
  )
}

export default PasswordUpdate
