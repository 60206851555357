import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { IoLocationOutline, IoSearchOutline } from 'react-icons/io5';
import { PiClockUser } from "react-icons/pi";
import picPro from '../../../../images/pp.png';
import { ScrollPanel } from 'primereact/scrollpanel';
import pinset from '../../../../images/icon-2/pinset.svg';
import CheckCircle from '../../../../images/icon-2/CheckCircle.svg';
import BackCircle from '../../../../images/icon-2/BackCircle.svg';
import XCircle from '../../../../images/icon-2/XCircle.svg';
import FilePdf from '../../../../images/icon-2/FilePdf.svg';
const AdminSubmissions = () => {
    return (
        <>
            <ScrollPanel className="custombar2 creator_task content-area">
                <div className="col-12 ps-3 ps-4 pe-4">
                    <div className='ps-3 pe-1'>
                        <div className="account-block mt-2 ">
                            <h6>Comment on my Instagram Reel</h6>

                            <div className="d-flex task_main_wrap">
                                <div className="task_process">
                                    <span>Status</span>
                                    {/* <div className="task_process_bar">
                                                                        <p>Progress <span>/</span> 51%</p>
                                                                    </div> */}
                                    <div className="progress_wrap" style={{ position: "relative" }}>
                                        <h3>In Progress</h3>
                                    </div>

                                </div>
                                <div className="task_process ps-3 pe-3">
                                    <span className="text-center w-100">Total Submission</span>
                                    <div className="task_process_bar text-center">
                                        <p>15 <span>/</span> 50%</p>
                                    </div>
                                </div>
                                <div className="task_process ps-3 pe-3">
                                    <span className="text-center w-100">Total Submission</span>
                                    <div className="task_process_bar text-center">
                                        <p>29 Jan, 2022</p>
                                    </div>
                                </div>
                                <div className="task_process ps-3 pe-3 " style={{ border: "0" }}>
                                    <span className="text-center w-100">Price</span>
                                    <div className="task_process_bar text-center">
                                        <p>$50  <span>/</span> Submission</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='ms-3 mb-4'>
                        <div className="table-bar">
                            <div className="table-bar-left">
                                <div className="table-bar-icon">
                                    <div className="table-nav">
                                        <div className="profile-menu">

                                        </div>
                                        <div className="profile-menu">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="successN" className="drop-btn">
                                                    <img src={require("../../../../images/icon/filter.svg").default} alt="" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <p style={{ fontSize: 15, fontWeight: 500 }}> Lorem ipsum </p>
                                                    <li className="drop-list">
                                                        <span className="custom-check">
                                                            <input
                                                                type="checkbox"
                                                                name="agentType"
                                                            />
                                                            <span className="checkMark"></span>
                                                        </span>
                                                        dolor sit amet
                                                    </li>
                                                    <li className="drop-list">
                                                        <span className="custom-check">
                                                            <input
                                                                type="checkbox"
                                                                name="agentType"
                                                            />
                                                            <span className="checkMark"></span>
                                                        </span>
                                                        dolor sit amet.
                                                    </li>


                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>


                                    <ul>
                                        <li title="Sort by Date">
                                            <img src={require("../../../../images/icon/sort.svg").default} alt="" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="table-bar-right">
                                <div className="header-search">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                    />
                                    <span className="left-icon"><IoSearchOutline
                                    /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="task_applications_wrap">
                        <div className="row g-4">
                            <div className="col-xl-4 col-lg-6 col-12">
                                <div className='account-block'>
                                    <div className="Submissions_details">
                                        <div className="application_date mb-0">
                                            <PiClockUser />
                                            <span>12 July, 2024 on 2:25 PM</span>
                                        </div>

                                        <div className="application_img">
                                            <img src={picPro} alt="" />
                                        </div>
                                    </div>
                                    <div className="application_para">
                                        <h3>Mehedi Hasan</h3>
                                        <p>Please check my submission and approve at your earliest convenience. Thanks for the opportunity. </p>
                                    </div>
                                    <div className="Submissions_info">
                                        <div className="total_sub_info">
                                            <div className='d-flex'>
                                                <img src={pinset} alt="" />
                                                <span>3</span>
                                            </div>
                                            <div className='d-flex'>
                                                <img src={FilePdf} alt="" />
                                                <span>12</span>
                                            </div>
                                        </div>
                                        <div className="total_sub_action">
                                            <img src={CheckCircle} alt="" />
                                            <img src={XCircle} alt="" />
                                            <img src={BackCircle} alt="" />
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12">
                                <div className='account-block'>
                                    <div className="Submissions_details">
                                        <div className="application_date mb-0">
                                            <PiClockUser />
                                            <span>12 July, 2024 on 2:25 PM</span>
                                        </div>

                                        <div className="application_img">
                                            <img src={picPro} alt="" />
                                        </div>
                                    </div>
                                    <div className="application_para">
                                        <h3>Mehedi Hasan</h3>
                                        <p>Please check my submission and approve at your earliest convenience. Thanks for the opportunity. </p>
                                    </div>
                                    <div className="Submissions_info">
                                        <div className="total_sub_info">
                                            <div className='d-flex'>
                                                <img src={pinset} alt="" />
                                                <span>3</span>
                                            </div>
                                            <div className='d-flex'>
                                                <img src={FilePdf} alt="" />
                                                <span>12</span>
                                            </div>
                                        </div>
                                        <div className="total_sub_action">
                                            <img src={CheckCircle} alt="" />
                                            <img src={XCircle} alt="" />
                                            <img src={BackCircle} alt="" />
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12">
                                <div className='account-block'>
                                    <div className="Submissions_details">
                                        <div className="application_date mb-0">
                                            <PiClockUser />
                                            <span>12 July, 2024 on 2:25 PM</span>
                                        </div>

                                        <div className="application_img">
                                            <img src={picPro} alt="" />
                                        </div>
                                    </div>
                                    <div className="application_para">
                                        <h3>Mehedi Hasan</h3>
                                        <p>Please check my submission and approve at your earliest convenience. Thanks for the opportunity. </p>
                                    </div>
                                    <div className="Submissions_info">
                                        <div className="total_sub_info">
                                            <div className='d-flex'>
                                                <img src={pinset} alt="" />
                                                <span>3</span>
                                            </div>
                                            <div className='d-flex'>
                                                <img src={FilePdf} alt="" />
                                                <span>12</span>
                                            </div>
                                        </div>
                                        <div className="total_sub_action">
                                            <img src={CheckCircle} alt="" />
                                            <img src={XCircle} alt="" />
                                            <img src={BackCircle} alt="" />
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-12">
                                <div className='account-block'>
                                    <div className="Submissions_details">
                                        <div className="application_date mb-0">
                                            <PiClockUser />
                                            <span>12 July, 2024 on 2:25 PM</span>
                                        </div>

                                        <div className="application_img">
                                            <img src={picPro} alt="" />
                                        </div>
                                    </div>
                                    <div className="application_para">
                                        <h3>Mehedi Hasan</h3>
                                        <p>Please check my submission and approve at your earliest convenience. Thanks for the opportunity. </p>
                                    </div>
                                    <div className="Submissions_info">
                                        <div className="total_sub_info">
                                            <div className='d-flex'>
                                                <img src={pinset} alt="" />
                                                <span>3</span>
                                            </div>
                                            <div className='d-flex'>
                                                <img src={FilePdf} alt="" />
                                                <span>12</span>
                                            </div>
                                        </div>
                                        <div className="total_sub_action">
                                            <img src={CheckCircle} alt="" />
                                            <img src={XCircle} alt="" />
                                            <img src={BackCircle} alt="" />
                                        </div>
                                    </div>


                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </ScrollPanel>
        </>
    )
}

export default AdminSubmissions
