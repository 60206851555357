import React from 'react'
import { useDispatch } from 'react-redux'
import { logoutAllDevice } from '../../../Redux/Actions/AuthActions'

const SecurityTabs = () => {

    const dispatch = useDispatch()
    const handleAllAccountLogOut = () => {

        dispatch(logoutAllDevice())
    }
    return (
        <>

            <div className="table-responsive mt-3 account-block Task">
                <table className="table theme-table">
                    <thead>
                        <tr>
                            <th>Login Time</th>
                            <th>Logout Time</th>
                            <th>Ip Address</th>
                            <th>Location</th>
                            <th>System Information</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>


                        <tr>
                            <td data-label="Login Time">10-10-2024 15:09</td>
                            <td data-label="Logout Time">10-10-2024 15:09</td>
                            <td data-label="Ip Address">198:47:474:74</td>
                            <td data-label="Location">Noida
                            </td>
                            <td data-label="Status">System Information
                            </td>
                            <td data-label="Status">
                                <span>Destroy</span>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div className="account-block mt-4 mb-5">
                <h6 className="pb-3">Security</h6>
                <div className="supportBlock" onClick={handleAllAccountLogOut}>
                    <h6>Log out of all devices</h6>
                    <p>Log out of all other active sessions on other devices besides this one.</p>
                </div>
                <div className="supportBlock">
                    <h6 className="txt-red">Delete my account</h6>
                    <p>Permanently delete the account and remove access from all devices.</p>
                </div>
            </div>
        </>
    )
}

export default SecurityTabs
