import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"


// dotask................................

export const onFetchCategory = (setData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("get_category", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData(res.data)
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                fetch: false
            })
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
        })
}
export const onFetchLevel = (setData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("get_level", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData(res.data)
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                fetch: false
            })
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
        })
}



export const onCreatePublishTask = (data, navigation, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-task", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigation('/creator/task')
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                create: false
            })
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
        })
}


export const onFetchPublishTask = (setCreateTask, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-task", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setCreateTask(res.data)
            } else {
                setCreateTask([])

            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                fetch: false
            })
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onFetchTaskData = (data, setData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-task-by-id", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData(res.data[0])
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                fetch: false
            })
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
        })
}
export const onUpdateTaskData = (data, navigation, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-task", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigation('/creator/task')
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                fetch: false
            })
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
        })
}
export const FetchTaskUserSubmissionData = (SubData, loader, setSubmissionData, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-usertask-submission", SubData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setSubmissionData(res.data);
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader({
                ...loader,
                fetch: false
            });

        }).catch((err) => {
            console.log(err);
            setLoader({
                ...loader,
                fetch: false
            });

        });
}


export const SubmissionTaskApprove = (data, loader, setLoader, handleClose, handleModalShow) => (dispatch, getState) => {

    commonAxios("approve-task", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                dispatch(setAlert(res.msg, "success"))
                handleModalShow();

            } else {
                console.log(res.msg)
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                approve: false
            })
            handleClose()
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                approve: false
            })

        })
}

export const SubmissionTaskReject = (data, loader, setLoader, handleClose) => (dispatch, getState) => {

    commonAxios("reject-task", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                dispatch(setAlert(res.msg, "success"))

            } else {
                console.log(res.msg)
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                reject: false
            })
            handleClose()
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                reject: false
            })

        })
}
export const submitUserReview = (data, setLoader, handleModalFalse) => (dispatch, getState) => {

    commonAxios("submit-user-review", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                dispatch(setAlert(res.msg, "success"))

            } else {
                console.log(res.msg)
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
            handleModalFalse()
        }).catch((err) => {
            console.log(err)
            setLoader(false)

        })
}


export const onFetchReview = (setReviewData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-creator-review", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setReviewData(res.data)
            } else {
                setReviewData([])
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                fetch: false
            })
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onFetchSpending = (SetSpendingData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("creator-spending", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                SetSpendingData(res.data)
            } else {
                SetSpendingData([])
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                fetch: false
            })
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
        })
}
export const onFetchDashboardData = (SetSpendingData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("creator-dashboard", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                SetSpendingData(res.data)
            } else {
                SetSpendingData([])
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                fetch: false
            })
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
        })
}