import React, { useState } from 'react'
import EarnRecommendedTask from './EarnRecommendedTask'
import { ScrollPanel } from 'primereact/scrollpanel'
import SidePanel from '../../Common/SidePanel'
import DashboardHeader from '../../Common/DashboardHeader';
import NavTabEarning from './NavTabEarning';
import PayoutRequestModal from '../ModalComponents/PayoutRequestModal';
import TitleBar from '../../Common/TitleBar';
import DashboardFooter from '../../Common/DashboardFooter';
const Earnings = () => {
    const [isActive, setIsActive] = useState(true);
    const toggleClass = () => {
        setIsActive(!isActive);
    };
    return (
        <>
            <TitleBar title="Earnings" />
            <div className="dashboard earnings_payouts container-fluid overflow-hidden">
                <div className="row">

                    <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>
                        <SidePanel />
                    </div>
                    <div className={` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
                        <div>
                            <div className="row">
                                <div className="col-xxl-10 col-xl-9 col-lg-8 ps-0 pe-0">
                                    <div className="dashboard-in main_task_wrapper">

                                        <DashboardHeader toggleSidebar={toggleClass} title="Earnings" />


                                        <ScrollPanel className="custombar2 content-area pt-0">
                                            <div className="main_task_details p-4">
                                                <div className="do_task_wrapper ">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <NavTabEarning />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ScrollPanel>
                                        <div className="d-none d-lg-block">
                                            <DashboardFooter />
                                        </div>


                                    </div>
                                </div>
                                <div className="col-xxl-2 col-xl-3 col-lg-4 ps-0">
                                    <EarnRecommendedTask />
                                </div>
                                <div className="d-block d-lg-none">
                                    <DashboardFooter />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default Earnings
