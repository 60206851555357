import { commonAxios } from "../../Global/CommonAxios";

export const fetchAdminDashboardAction= (setSubmissionData,setLoader,loader) => (dispatch, getState) => {

    commonAxios("admin-dashboard", {}, dispatch,getState().auth.token)
        .then((res) => {
             
            if (res.status) {
               
                setSubmissionData(res.data)
               
            } 
            else {
              
               console.log(res)
            }
           setLoader({
            ...loader,
            fetch:false
           })
        }).catch((err) => {
         console.log(err)
        //  setLoader(false)
        })
}