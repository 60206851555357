import React, { useEffect, useState } from 'react'
import { PieChart, Pie, Sector, Cell } from "recharts";
import { ScrollPanel } from 'primereact/scrollpanel';
import { GoDotFill } from "react-icons/go";
import { BsFolder } from "react-icons/bs";
import { FaArrowTrendUp } from "react-icons/fa6";
import { PiBatteryChargingLight, PiListChecks, PiUsersThree } from "react-icons/pi";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import TitleBar from "../../Common/TitleBar";
import DashboardHeader from "../../Common/DashboardHeader";
import SidePanelTaskCreator from '../../Common/SidePanelTaskCreator';
import { useDispatch } from 'react-redux';
import { onFetchDashboardData } from '../../../Redux/Actions/TaskCreatorAction';
import { MdCurrencyRupee } from 'react-icons/md';
import { Link } from 'react-router-dom';
import DashboardFooter from '../../Common/DashboardFooter';
import { FcCancel } from "react-icons/fc";


const CreatorDashboard = () => {
    const [isActive, setIsActive] = useState(true);
    const toggleClass = () => {
        setIsActive(!isActive);
    };


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch()

    const [loader, setLoader] = useState({
        fetch: false
    })
    const [dashData, setDashData] = useState({
        details: {},
        latesttask: [],
        task_status: {}

    });
    const handleDashChange = () => {
        setLoader({
            fetch: true
        })
        dispatch(onFetchDashboardData(setDashData, loader, setLoader))
    }
    useEffect(() => {
        handleDashChange();
    }, [])



    // piechart..........

    const data = [
        { name: "Group A", value: dashData.task_status.pending },
        { name: "Group B", value: dashData.task_status.rejected },
        { name: "Group c", value: dashData.task_status.approved }
    ];
    const COLORS = ["#BAEDBD", "#95A4FC", "#1C1C1C"];
    // BarChart.......
    const data1 = [
        {
            name: 'Jan',
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Feb',
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Mar',
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Apr',
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'May',
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Jun',
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Jul',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Aug',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Sep',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Oct',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Nov',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
        {
            name: 'Dec',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];
    return (
        <>
            <TitleBar title="Dashboard" />
            <div className="dashboard creator_dashboard  container-fluid overflow-hidden">
                <div className="row">
                    <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>
                        <SidePanelTaskCreator />
                    </div>
                    <div className={` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
                        <div>
                            <div className="row">
                                <div className="col-12 ps-0 pe-3">
                                    <div className="dashboard-in">
                                        <DashboardHeader toggleSidebar={toggleClass} title="Dashboard" />

                                        <ScrollPanel className="custombar2 content-area">
                                            <div className="p-4">
                                                <div className="do_task_wrapper ">
                                                    <div className="row adjust_class">
                                                        <div className="col-xxl-2 col-lg-4 col-md-6 col-12 mt-xxl-0 mt-3 ">
                                                            <div className="total_task">
                                                                <div className="d-flex justify-content-between gap-3 align-items-center">
                                                                    <h3>Total Tasks</h3>
                                                                    <BsFolder className="m_icon" />
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <span className="task_no" style={{ fontSize: "24px" }}>{dashData.details.totaltask}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-2 col-lg-4 col-md-6 col-12 mt-xxl-0 mt-3 ">
                                                            <div className="total_task d2">
                                                                <div className="d-flex justify-content-between gap-3 align-items-center">
                                                                    <h3>Ongoing Tasks</h3>
                                                                    <PiListChecks className="m_icon" />
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <span className="task_no" style={{ fontSize: "24px" }}>{dashData.details.pendingtask}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-2 col-lg-4 col-md-6 col-12 mt-xxl-0 mt-3 ">
                                                            <div className="total_task">
                                                                <div className="d-flex justify-content-between gap-3 align-items-center">
                                                                    <h3>Approval Pending</h3>
                                                                    <PiUsersThree className="m_icon" />
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <span className="task_no" style={{ fontSize: "24px" }}>{dashData.details.approvedtask}</span>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-2 col-lg-4 col-md-6 col-12 mt-xxl-0 mt-3 ">
                                                            <div className="total_task d2">
                                                                <div className="d-flex justify-content-between gap-3 align-items-center">
                                                                    <h3>My Spending</h3>
                                                                    <PiBatteryChargingLight className="m_icon" />
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <span className="task_no" style={{ fontSize: "24px" }}>{dashData.details.totalspending}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-2 col-lg-4 col-md-6 col-12 mt-xxl-0 mt-3 ">
                                                            <div className="total_task d2">
                                                                <div className="d-flex justify-content-between gap-3 align-items-center">
                                                                    <h3>Rejected Task</h3>
                                                                    <FcCancel className='m_icon' />

                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <span className="task_no" style={{ fontSize: "24px" }}>{dashData.details.rejectedtask}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xxl-3 col-xl-4 col-12">
                                                        <div className="campaign-stat-left w-100">
                                                            <div className="account-block status">
                                                                <h6>Task Status</h6>
                                                                <div>

                                                                    <div className='dash_graph' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400, maxWidth: "100%" }}>
                                                                        <ResponsiveContainer width="100%" height="100%">
                                                                            <PieChart>
                                                                                <Pie
                                                                                    data={data}
                                                                                    cx="50%"
                                                                                    cy="50%"
                                                                                    innerRadius={60}
                                                                                    outerRadius={80}
                                                                                    fill="#8884d8"
                                                                                    paddingAngle={5}
                                                                                    dataKey="value"
                                                                                >
                                                                                    {data.map((entry, index) => (
                                                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                                    ))}
                                                                                </Pie>
                                                                            </PieChart>
                                                                        </ResponsiveContainer>
                                                                    </div>
                                                                    <div style={{ marginTop: "-140px" }}>
                                                                        <div className="task_status">
                                                                            <p><GoDotFill style={{ color: "#1C1C1C", marginRight: "3px" }} />Competed</p>
                                                                            <span>{dashData.task_status.approved}</span>
                                                                        </div>
                                                                        <div className="task_status">
                                                                            <p><GoDotFill style={{ color: "#BAEDBD", marginRight: "3px" }} />In Progress</p>
                                                                            <span>{dashData.task_status.pending}</span>
                                                                        </div>
                                                                        <div className="task_status">
                                                                            <p><GoDotFill style={{ color: "#95A4FC", marginRight: "3px" }} />Rejected </p>
                                                                            <span>{dashData.task_status.rejected}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-9 col-xl-8 col-12">
                                                        <div className="campaign-stat-right w-100 ps-0 pe-0">
                                                            <div className="account-block Task">
                                                                <div className="d-flex justify-content-between">
                                                                    <h6>Latest Tasks</h6>
                                                                    <Link to="/creator/task">Show All</Link>
                                                                </div>
                                                                <div className="table-responsive mt-3">
                                                                    <table className="table theme-table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Title</th>
                                                                                <th>Price</th>
                                                                                <th>Due Date</th>
                                                                                <th>Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>

                                                                            {dashData.latesttask.length > 0 ?
                                                                                dashData.latesttask.slice(0, 6).map((curElem, index) => {
                                                                                    return (
                                                                                        <React.Fragment key={index}>
                                                                                            <tr>
                                                                                                <td data-label="Title">{curElem.title} </td>
                                                                                                <td data-label="Price"><MdCurrencyRupee />{curElem.price}</td>
                                                                                                <td data-label="Due Date">{curElem.created_at}</td>
                                                                                                <td data-label="Status">
                                                                                                    <span className={`table-stat ${curElem.status === "1" ? "active" : curElem.status === "0" ? "inactive" : "banned"}`}>
                                                                                                        {curElem.status === "1" ? "Complete" : curElem.status === "0" ? "Pending" : "Reject"}
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })

                                                                                : ""

                                                                            }

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="account-block mt-4 mb-3 mb-lg-5">
                                                    <h6>Tasks Overview</h6>


                                                    <div className="task_overview">
                                                        <ResponsiveContainer width="100%" height={300}>
                                                            <BarChart
                                                                data={data1}
                                                                margin={{
                                                                    top: 5,
                                                                    right: 30,
                                                                    left: 20,
                                                                    bottom: 5,
                                                                }}
                                                                barSize={20}
                                                            >
                                                                <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                                                                <YAxis />
                                                                <Tooltip />
                                                                <Legend />
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <Bar dataKey="pv" fill="#A8C5DA" background={{ fill: "#eee" }} />
                                                            </BarChart>
                                                        </ResponsiveContainer>
                                                    </div>
                                                </div>
                                            </div>

                                        </ScrollPanel>
                                        <DashboardFooter />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreatorDashboard
