import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import ReviewsReceivedTabs from "./ReviewsReceivedTabs";
import ReviewsGiven from "./ReviewsGiven";
import DashboardHeader from "../../Common/DashboardHeader";
import DashboardFooter from "../../Common/DashboardFooter";
import SidePanelTaskCreator from '../../Common/SidePanelTaskCreator';
import { ScrollPanel } from "primereact/scrollpanel";
import { useDispatch } from 'react-redux';
import { onFetchReview } from '../../../Redux/Actions/TaskCreatorAction';
function ReviewsTaskCreator() {
   const [isActive, setIsActive] = useState(false);
   const dispatch = useDispatch()
   const [reviewData, setReviewData] = useState({
      recieved_review: [],
      given_review: []
   });
   const [loader, setLoader] = useState({
      fetch: false,

   })

   const handleFetchReview = () => {
      setLoader({
         fetch: true,
      })

      dispatch(onFetchReview(setReviewData, loader, setLoader))
   }
   const toggleClass = () => {
      setIsActive(!isActive);
   };

   const [isActive2, setIsActive2] = useState(false);
   const toggleNotification = () => {
      setIsActive2(!isActive2);
   };
   useEffect(() => {
      handleFetchReview()
   }, [])
   return (
      <>
         <div className={isActive ? 'dashboard dashboard-modified activeSidePanel' : 'dashboard dashboard-modified'}>
            <SidePanelTaskCreator />

            <div className="dashboard-in">
               <DashboardHeader toggleSidebar={toggleClass} notificationToggle={toggleNotification} title="Reviews" />
               <ScrollPanel className="custombar2 content-area">
                  <div className="content-area-in tab-style">
                     <Tab.Container id="left-tabs-example" defaultActiveKey="tab-1">
                        <div className="tab-bar">
                           <Nav variant="pillsb">
                              <Nav.Item><Nav.Link eventKey="tab-1">Reviews Received</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-2">Reviews Given</Nav.Link></Nav.Item>
                              {/* <Nav.Item><Nav.Link eventKey="tab-3">Pending Reviews</Nav.Link></Nav.Item> */}
                           </Nav>
                        </div>
                        <Tab.Content>
                           <Tab.Pane eventKey="tab-1">
                              <ReviewsReceivedTabs
                                 reviewData={reviewData.recieved_review}
                                 loader={loader}
                              />
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-2">
                              <ReviewsGiven
                                 reviewData={reviewData.given_review}
                                 loader={loader}

                              />
                           </Tab.Pane>


                           {/* <Tab.Pane eventKey="tab-3">
                              <PendingReviews />
                           </Tab.Pane> */}
                        </Tab.Content>
                     </Tab.Container>
                  </div>
               </ScrollPanel>
               <DashboardFooter />
            </div>
         </div>

      </>
   )
}
export default ReviewsTaskCreator;