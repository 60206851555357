import React, { useEffect, useRef, useState } from 'react'
import { ScrollPanel } from 'primereact/scrollpanel';
import DashboardHeader from '../../Common/DashboardHeader';
import { Form } from 'react-bootstrap';
import calendar from '../../../images/icon/CalendarBlank.svg'
import { IoSearchOutline } from 'react-icons/io5';
import ContentEditable from 'react-contenteditable'
import SidePanelTaskCreator from '../../Common/SidePanelTaskCreator';
import { Calendar } from 'primereact/calendar';
import { useLocation, useNavigate } from 'react-router-dom';
import { onCreatePublishTask, onFetchCategory, onFetchLevel, onFetchTaskData, onUpdateTaskData } from '../../../Redux/Actions/TaskCreatorAction';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { FaSpinner } from "react-icons/fa";
import { RiEdit2Line } from "react-icons/ri";
import DashboardFooter from '../../Common/DashboardFooter';
import { Dropdown } from 'primereact/dropdown';
import SummerNote from './SummerNote';
import CreateUpload from './CreateUpload';
const CreateNewTask = () => {
    const navigation = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    // const [state, setState] = useState({ description: '' })
    const [loader, setLoader] = useState({
        create: false
    })
    const [editing, setEditing] = useState(
        location?.state?.id?.slug ? false : true
    )
    const [categories, setCategories] = useState([])
    const [experience, setExperience] = useState([])
    const [data, setData] = useState({
        category: "",
        experience: "",
        title: "",
        description: "",
        max_allowed_submission: "",
        price: "",
        type: "unlimited",
        due_date: "",
        task_status: "",
        is_comment: "",
        feature: "",
        video_upload: "",
        dt_task: "",
        evergreen: "",
        social_accounts: "",
        bonus: "",
        prompt: "",
        caption: "",
        assets_name: "",
        Task_type: ""
    });
    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();

    // Calculate the previous month and year
    let prevMonth = month === 0 ? 11 : month - 1;
    let prevYear = month === 0 ? year - 1 : year;

    // Calculate the next month and year
    let nextMonth = month === 11 ? 0 : month + 1;
    let nextYear = month === 11 ? year + 1 : year;

    // Set minDate to the start of the previous month
    let minDate = new Date(prevYear, prevMonth, 1);


    const handleChange = (e, name) => {

        if (name) {
            const { innerText } = e.currentTarget;
            setData({ ...data, [name]: innerText });
        } else {
            const { value, name } = e.target;
            setData({ ...data, [name]: value });
        }
    };
    const handleSubmissionClick = (type) => {
        setData({ ...data, type: type });
    };

    const handleEditClick = () => {
        setEditing((prev) => !prev)
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!data.title) {
            dispatch(setAlert("Task title is required.", "danger"))
            return;
        }
        if (!data.description) {
            dispatch(setAlert("Task description is required.", "danger"))
            return;
        }

        if (!data.due_date) {
            dispatch(setAlert("Due date is required.", "danger"))
            return;
        }
        setLoader({
            ...loader,
            create: true
        })
        if (location?.state?.id?.slug) {
            dispatch(onUpdateTaskData(data, navigation, loader, setLoader))
        } else {
            dispatch(onCreatePublishTask(data, navigation, loader, setLoader))
        }
    }
    const fetchTaskData = () => {
        let data = {
            slug: location?.state?.id?.slug
        }
        dispatch(onFetchTaskData(data, setData, loader, setLoader))
    }
    const fetchCategory = () => {
        dispatch(onFetchCategory(setCategories, loader, setLoader))
    }
    const fetchExperience = () => {
        dispatch(onFetchLevel(setExperience, loader, setLoader))
    }
    useEffect(() => {
        if (location?.state?.id?.slug) {
            fetchTaskData()
        }

    }, [location])
    useEffect(() => {
        fetchCategory()
        fetchExperience()

    }, [])

    const option = categories?.map((curElem, i) => {
        return {
            name: curElem.name,
            value: curElem.id
        };
    });

    const option2 = experience?.map((curElem, i) => {
        return {
            name: curElem.name,
            value: curElem.id
        };
    })
    const option3 = [{ name: "Open", value: "1" }, { name: "Closed", value: "0" }]



    return (
        <>
            <div className="dashboard creator_new_task container-fluid overflow-hidden create-task-footer">
                <div className="row">
                    {
                        location.state?.id?.slug ? "" :

                            <div className="col-lg-2 col-md-3 col-sm-4 col-12 ps-0">
                                <SidePanelTaskCreator />
                            </div>
                    }
                    <div className={location.state?.id?.slug ? "col-lg-12 col-md-9 col-sm-8 col-12 p-0 " : "col-lg-10 col-md-9 col-sm-8 col-12 p-0"}>
                        <div className="row">
                            <div className="col-12 ps-0">
                                <div className="dashboard-in">
                                    {
                                        location.state?.id?.slug ? "" :

                                            <DashboardHeader title="Create new task" />
                                    }


                                    <div className="new_task_head px-4 py-3 p-3">
                                        <h3>{location.state?.id?.slug ? "" : "Create New Task"}</h3>
                                    </div>
                                    <ScrollPanel className="custombar2 content-area pt-2">

                                        <form onSubmit={handleSubmit}>
                                            <div className='container-fluid'>
                                                <div className='row'>
                                                    <div className='col-lg-12'>
                                                        <div className='account-block ms-3 me-3 '>
                                                            <div className="d-flex justify-content-between">
                                                                <h3>Task Type</h3>
                                                                {
                                                                    location.state?.id?.slug ? (
                                                                        <span style={{ cursor: "pointer" }} onClick={handleEditClick}>
                                                                            <RiEdit2Line /> Edit Details
                                                                        </span>
                                                                    ) : ""
                                                                }

                                                            </div>
                                                            {/* <div className="row g-3">
                                                                <div className="col-lg-6 col-12">
                                                                    <div
                                                                        className={`submission_wrap mt-3 py-4 ${data.type === "limited" ? "border border-dark" : ""}`}
                                                                        onClick={() => handleSubmissionClick("limited")}
                                                                    >
                                                                        <div className='d-flex justify-content-between align-items-center'>
                                                                            <h3>Limited Submission</h3>
                                                                            <div className="form-check">
                                                                                <input
                                                                                    className="form-check-input "
                                                                                    type="radio"
                                                                                    disabled={!editing}
                                                                                    name="type"
                                                                                    checked={data.type === "limited"}
                                                                                    readOnly
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='new_task_para mt-2'>
                                                                            <p>Limited Submission
                                                                                There are limits to the number of times you can participate in this task, allowing you to contribute multiple times</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-12">
                                                                    <div
                                                                        className={`submission_wrap mt-3 py-4 ${data.type === "unlimited" ? "border border-dark" : ""}`}
                                                                        onClick={() => handleSubmissionClick("unlimited")}
                                                                    >
                                                                        <div className='d-flex justify-content-between align-items-center'>
                                                                            <h3>Unlimited Submission</h3>
                                                                            <div className="form-check">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="radio"
                                                                                    name="type"
                                                                                    disabled={!editing}
                                                                                    checked={data.type === "unlimited"}
                                                                                    readOnly
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='new_task_para mt-2'>
                                                                            <p>Unlimited Submission
                                                                                There are no limits to the number of times you can participate in this task, allowing you to contribute multiple times</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                            <div className="col-12">

                                                                <div className="submission_wrap mt-3 py-4 ">
                                                                    <span className='mb-2'>Task Category</span>
                                                                    <div style={{ marginLeft: "-10px" }}>
                                                                        <Dropdown
                                                                            value={data.category}
                                                                            onChange={(e) => handleChange(e)}
                                                                            options={option}
                                                                            optionLabel="name"
                                                                            placeholder="Select Type"
                                                                            className="input ms-2 p-2 rounded-3 border-0"
                                                                            name="category"
                                                                            disabled={!editing}
                                                                        />
                                                                        {/* <Form.Select
                                                                            aria-label="Task selection"
                                                                            value={data.category}
                                                                            name="category"
                                                                            onChange={handleChange}
                                                                            disabled={!editing}
                                                                            required

                                                                        >
                                                                            <option value="" disabled >Select a task category</option>
                                                                            {categories.length > 0 ?
                                                                                categories.map((category, index) => {
                                                                                    return (
                                                                                        <React.Fragment key={index}>
                                                                                            <option value={category.id}>
                                                                                                {category.name}
                                                                                            </option>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })

                                                                                : ""

                                                                            }

                                                                        </Form.Select> */}
                                                                    </div>
                                                                </div>
                                                                <div className="submission_wrap submission_bg mt-3  ">
                                                                    <span>Task Title</span>

                                                                    <ContentEditable
                                                                        html={data.title}
                                                                        disabled={!editing}
                                                                        onChange={(e) => handleChange(e, 'title')}
                                                                        tagName="h3"
                                                                        className="mt-2"
                                                                        style={{ fontWeight: "500" }}
                                                                    />

                                                                </div>
                                                                <div className="submission_wrap submission_bg mt-3 pt-2">
                                                                    <span className='pb-2'>Task Description</span>
                                                                    <SummerNote state={data} setState={setData} editing={editing} />
                                                                    {/* <textarea
                                                                        value={data.description}
                                                                        onChange={(e) => handleChange(e)}
                                                                        rows="5"
                                                                        name='description'
                                                                        placeholder="Enter Description"
                                                                        className="mt-2 form-control h-100 mb-3 text_description w-100 border-0" // Removed form-check-input
                                                                    /> */}


                                                                </div>
                                                                <div className="submission_wrap  mt-3 py-4 ">
                                                                    <span className='mb-2'>Experience Required</span>
                                                                    <div style={{ marginLeft: "-10px" }}>
                                                                        <Dropdown
                                                                            value={data.experience}
                                                                            onChange={(e) => handleChange(e)}
                                                                            options={option2}
                                                                            optionLabel="name"
                                                                            placeholder="Select Type"
                                                                            className="input ms-2 p-2 rounded-3 border-0"
                                                                            name="experience"
                                                                            disabled={!editing}
                                                                        />
                                                                        {/* <Form.Select
                                                                            aria-label="Experience selection"
                                                                            value={data.experience}
                                                                            disabled={!editing}
                                                                            name="experience"
                                                                            onChange={handleChange}
                                                                        >
                                                                            <option value="" disabled >Select a task category</option>
                                                                            {experience.length > 0 ?
                                                                                experience.map((experience, index) => {
                                                                                    return (
                                                                                        <React.Fragment key={index}>
                                                                                            <option value={experience.id}>
                                                                                                {experience.name}
                                                                                            </option>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })

                                                                                : ""

                                                                            }

                                                                        </Form.Select> */}
                                                                    </div>
                                                                </div>
                                                                {/* <div className="submission_wrap submission_bg mt-3 pt-2">
                                                                    <span className='pb-2'>Prompt
                                                                    </span>
                                                                    <textarea
                                                                        value={data.prompt}
                                                                        onChange={(e) => handleChange(e)}
                                                                        rows="5"
                                                                        name='prompt'
                                                                        placeholder="Enter the Prompt here"
                                                                        className="mt-2 form-control h-100 mb-3 text_description w-100 border-0" // Removed form-check-input
                                                                        disabled={!editing}
                                                                    />


                                                                </div> */}
                                                                {/* <div className='row'>
                                                                    <div className='col-lg-3'>
                                                                        <div className="submission_wrap  mt-3">
                                                                            <span className='mb-2'>Is Commentable Task
                                                                            </span>
                                                                            <div style={{ marginLeft: "-10px" }}>
                                                                                <Dropdown
                                                                                    value={data.is_comment}
                                                                                    onChange={(e) => handleChange(e)}
                                                                                    options={[{ name: "Yes", value: "1" }, { name: "No", value: "0" }]}
                                                                                    optionLabel="name"
                                                                                    placeholder="Select Type"
                                                                                    className="input ms-2 p-2 rounded-3 border-0"
                                                                                    name="is_comment"
                                                                                    disabled={!editing}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-3'>
                                                                        <div className="submission_wrap  mt-3">
                                                                            <span className='mb-2'>Is Feature Task
                                                                            </span>
                                                                            <div style={{ marginLeft: "-10px" }}>
                                                                                <Dropdown
                                                                                    value={data.feature}
                                                                                    onChange={(e) => handleChange(e)}
                                                                                    options={[{ name: "Yes", value: "1" }, { name: "No", value: "0" }]}
                                                                                    optionLabel="name"
                                                                                    placeholder="Select Type"
                                                                                    className="input ms-2 p-2 rounded-3 border-0"
                                                                                    name="feature"
                                                                                    disabled={!editing}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-3'>
                                                                        <div className="submission_wrap  mt-3">
                                                                            <span className='mb-2'>Is Video Upload Task
                                                                            </span>
                                                                            <div style={{ marginLeft: "-10px" }}>
                                                                                <Dropdown
                                                                                    value={data.video_upload}
                                                                                    onChange={(e) => handleChange(e)}
                                                                                    options={[{ name: "Yes", value: "1" }, { name: "No", value: "0" }]}
                                                                                    optionLabel="name"
                                                                                    placeholder="Select Type"
                                                                                    className="input ms-2 p-2 rounded-3 border-0"
                                                                                    name="video_upload"
                                                                                    disabled={!editing}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-3'>
                                                                        <div className="submission_wrap  mt-3">
                                                                            <span className='mb-2'>Is DT Task
                                                                            </span>
                                                                            <div style={{ marginLeft: "-10px" }}>
                                                                                <Dropdown
                                                                                    value={data.dt_task}
                                                                                    onChange={(e) => handleChange(e)}
                                                                                    options={[{ name: "Yes", value: "1" }, { name: "No", value: "0" }]}
                                                                                    optionLabel="name"
                                                                                    placeholder="Select Type"
                                                                                    className="input ms-2 p-2 rounded-3 border-0"
                                                                                    name="dt_task"
                                                                                    disabled={!editing}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                                <div className='row'>
                                                                    {/* <div className='col-lg-4'>
                                                                        <div className="submission_wrap mt-3">
                                                                            <span className='mb-2'>Evergreen Task

                                                                            </span>
                                                                            <div style={{ marginLeft: "-10px" }}>
                                                                                <Dropdown
                                                                                    value={data.evergreen}
                                                                                    onChange={(e) => handleChange(e)}
                                                                                    options={[{ name: "Yes", value: "1" }, { name: "No", value: "0" }]}
                                                                                    optionLabel="name"
                                                                                    placeholder="Select Type"
                                                                                    className="input ms-2 p-2 rounded-3 border-0"
                                                                                    name="evergreen"
                                                                                    disabled={!editing}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                    <div className=''>
                                                                        <div className="submission_wrap submission_bg mt-3" style={{ minHeight: "100px" }}>
                                                                            <span className='mb-2'>Bonus Points
                                                                            </span>
                                                                            <div style={{ marginLeft: "0px" }}>
                                                                                <input
                                                                                    type="number"
                                                                                    name="bonus"
                                                                                    value={data.bonus}
                                                                                    placeholder='Enter Bonus Points'
                                                                                    disabled={!editing}
                                                                                    onChange={handleChange}
                                                                                    onKeyDown={(e) => {
                                                                                        if (!/^\d$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    style={{ border: "none", fontWeight: "500", width: "100%" }}
                                                                                    required
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-lg-6'>
                                                                        <div className="submission_wrap  mt-3">
                                                                            <span className='mb-2'>Task Type
                                                                            </span>
                                                                            <div style={{ marginLeft: "-10px" }}>
                                                                                <Dropdown
                                                                                    value={data.Task_type}
                                                                                    onChange={(e) => handleChange(e)}
                                                                                    options={[{ name: "Commented Task", value: "1" }, { name: "Feature Task", value: "2" }, { name: "Video Upload Task", value: "3" }, { name: "Dt Task", value: "4" }]}
                                                                                    optionLabel="name"
                                                                                    placeholder="Select Type"
                                                                                    className="input ms-2 p-2 rounded-3 border-0"
                                                                                    name="Task_type"
                                                                                    disabled={!editing}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-6'>
                                                                        <div className="submission_wrap  mt-3">
                                                                            <span className='mb-2'>Social Media Type
                                                                            </span>
                                                                            <div style={{ marginLeft: "-10px" }}>
                                                                                <Dropdown
                                                                                    value={data.social_accounts}
                                                                                    onChange={(e) => handleChange(e)}
                                                                                    options={[{ name: "Facebook", value: "1" }, { name: "Instagram", value: "2" }, { name: "Twitter", value: "3" }, { name: "Youtube", value: "4" }, { name: "Quora", value: "5" }, { name: "Others", value: "6" }]}
                                                                                    optionLabel="name"
                                                                                    placeholder="Select Type"
                                                                                    className="input ms-2 p-2 rounded-3 border-0"
                                                                                    name="social_accounts"
                                                                                    disabled={!editing}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className=''>
                                                                        <div className="submission_wrap submission_bg mt-3" style={{ minHeight: "100px" }}>
                                                                            <span className='mb-2'>No of submission
                                                                            </span>
                                                                            <div style={{ marginLeft: "0px" }}>
                                                                                <input
                                                                                    type="number"
                                                                                    name="bonus"
                                                                                    value={data.bonus}
                                                                                    placeholder='No of submission'
                                                                                    disabled={!editing}
                                                                                    onChange={handleChange}
                                                                                    onKeyDown={(e) => {
                                                                                        if (!/^\d$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    style={{ border: "none", fontWeight: "500", width: "100%" }}
                                                                                    required
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* {data.type !== "unlimited" ?
                                                                    <div className="submission_wrap submission_bg mt-3  ">
                                                                        <span>Maximum Allowed Submission</span>
                                                                        <input
                                                                            type="number"
                                                                            name="max_allowed_submission"
                                                                            value={data.max_allowed_submission}
                                                                            disabled={!editing}

                                                                            onChange={handleChange}
                                                                            placeholder='enter submission'
                                                                            className="mt-2"
                                                                            onKeyDown={(e) => {
                                                                                if (!/^\d$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }}
                                                                            style={{ fontWeight: "500", width: "100%", border: "none" }}
                                                                            required
                                                                        />

                                                                    </div>

                                                                    : ""

                                                                } */}
                                                                {/* <div className="submission_wrap submission_bg mt-3  ">
                                                                    <span className='mb-2'>Caption</span>
                                                                    <input
                                                                        type="text"
                                                                        name="caption"
                                                                        value={data.caption}
                                                                        placeholder='Enter caption'
                                                                        disabled={!editing}
                                                                        onChange={handleChange}

                                                                        style={{ border: "none", fontWeight: "500", width: "100%" }}
                                                                        required
                                                                    />
                                                                </div> */}

                                                                <div className="submission_wrap submission_bg mt-3  ">
                                                                    <span className='mb-2'>₹ Price ( Per submission )</span>
                                                                    <input
                                                                        type="number"
                                                                        name="price"
                                                                        value={data.price}
                                                                        placeholder='Enter price'
                                                                        disabled={!editing}
                                                                        onChange={handleChange}
                                                                        onKeyDown={(e) => {
                                                                            if (!/^\d$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                        style={{ border: "none", fontWeight: "500", width: "100%" }}
                                                                        required
                                                                    />
                                                                </div>
                                                                <div className="submission_wrap submission_bg mt-3 position-relative  ">
                                                                    <span>Due Date</span>
                                                                    <div className="flex-aut pt-2">
                                                                        <div>
                                                                            <Calendar
                                                                                id="buttondisplay"
                                                                                value={data.due_date}
                                                                                onChange={(e) => setData({ ...data, due_date: e.value })}
                                                                                disabled={!editing}
                                                                                showIcon
                                                                                dateFormat="dd/mm/yy"
                                                                                panelClassName="fixed-calendar"
                                                                                minDate={today}
                                                                                placeholder='Select date'
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="submission_wrap  mt-3 py-4 ">
                                                                    <span className='mb-2'>Task Status</span>
                                                                    <div style={{ marginLeft: "-10px" }}>
                                                                        <Dropdown
                                                                            value={data.task_status}
                                                                            onChange={(e) => handleChange(e)}
                                                                            options={option3}
                                                                            optionLabel="name"
                                                                            placeholder="Select Type"
                                                                            className="input ms-2 p-2 rounded-3 border-0"
                                                                            name="task_status"
                                                                            disabled={!editing}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <CreateUpload
                                                                    editing={editing}
                                                                    data={data}
                                                                    handleChange={handleChange}
                                                                />
                                                                <div className="new_task_create ">
                                                                    {/* <button className='theme-btn light' type="button">Cancel</button> */}

                                                                    <button className='theme-btn' type='submit' disabled={!editing} style={!editing ? { color: "#fff", background: "gray" } : {}}>
                                                                        {loader.create ? (
                                                                            <>{location.state?.id?.slug ? "Updating Task" : "Publishing Task "} <FaSpinner className="spin" size={15} /></>
                                                                        ) : (
                                                                            <>
                                                                                {location.state?.id?.slug ? "Update Task" : "Create Task "}
                                                                            </>
                                                                        )}
                                                                    </button>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </ScrollPanel>
                                    <DashboardFooter />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CreateNewTask
