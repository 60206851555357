import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { RxCross1 } from 'react-icons/rx';
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaSpinner } from 'react-icons/fa';
import { RiDeleteBin5Line } from "react-icons/ri";

const AdminBanModel = (props) => {


    return (
        <>
            <Modal className="theme-modal admin-approve reject-modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>
                <Modal.Body>
                    <div className='modal-btn'><span style={{ right: "20px", top: "20px" }} className='ms-auto' onClick={props.handleClose}><RxCross1 /></span></div>
                    <div className="py-5">
                        <span className='bin-icon mx-auto mb-3'>
                            <RiDeleteBin5Line style={{ fontSize: "25px", color: "#d92d20" }} />
                        </span>
                        <div className='d-flex align-items-start gap-3'>
                            <div className="modal-textarea w-100 ">
                                <p className='mb-3 fw-semibold text-center' style={{ fontSize: "15px" }}>Are you sure you want to reject this {props.type === "submission" ? "task" : "user"}?</p>
                                <textarea className="form-control" id="" rows="4" placeholder='Reason to reject' name="reason" value={props.reason} onChange={(e) => props.setReason(e.target.value)}></textarea>
                            </div>
                        </div>
                        <div className="verify_btn mt-4 d-flex justify-content-center gap-3">
                            <button className="theme-btn cancel-btn" type="button" style={{ padding: "8px 24px" }} onClick={props.handleClose}>Cancel </button>
                            <button className="theme-btn approve-btn" type="button" style={{ padding: "8px 24px" }} onClick={props.handleBanAccount} >Reject{props.loader.active ? <FaSpinner className='spin ms-1' style={{ color: "#fff" }} /> : ""}  </button></div>
                    </div>
                </Modal.Body>
            </Modal >

        </>
    )
}

export default AdminBanModel