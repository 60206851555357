import React from 'react'
import { PiUserCircleLight } from "react-icons/pi";
import { GrLocation } from "react-icons/gr";
import { HiOutlineEnvelope } from "react-icons/hi2";

const ProfileInfo = (props) => {
    
    
  return (
   <>
    <div className="account-block relative">
                        <div className="accountPic"><img src={props?.data?.profile?props?.data?.profile:"https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg"} alt="" /></div>
                        <div className="contInfo">
                            <h5>{props?.data?.name}</h5>
                            <ul>
                                {/* <li><PiUserCircleLight /><span>Software Product Manager</span></li> */}
                               
                                <li><HiOutlineEnvelope /> <span>{props?.data?.email}</span></li>
                            </ul>
                        </div>
                        <div className="contStat">
                            <ul>
                                <li>
                                    <h6>Profile Completion</h6>
                                    <div className="progressBar mt-2">
                                        <span className="progressBar-mark" style={{width:`${props?.data?.profile_compleation}%`}}></span>
                                        <span className="progressBar-num">{props?.data?.profile_compleation}%</span>
                                    </div>
                                </li>
                                <li>
                                    <h6>Earnings</h6>
                                    <h5>{props?.data?.earning}</h5>
                                </li>
                                <li>
                                    <h6>Tasks</h6>
                                    <h5>{props?.data?.task}</h5>
                                </li>
                                <li>
                                    <h6>Success Rate</h6>
                                    <h5>{props?.data?.success_rate}</h5>
                                </li>
                            </ul>
                        </div>
            </div>
   </>
  )
}

export default ProfileInfo