import React, { useEffect, useState } from "react";

import { PiUserCircleLight } from "react-icons/pi";
import { GrLocation } from "react-icons/gr";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { IoCalendarClearOutline } from "react-icons/io5";

import Modal from 'react-bootstrap/Modal';
import { MultiSelect } from 'primereact/multiselect';
import { ScrollPanel } from 'primereact/scrollpanel';
import { useDispatch, useSelector } from "react-redux";
import { changeProfile, loadUser, logoutAllDevice, userInformation } from "../../../Redux/Actions/AuthActions";
import { fetchData } from "../../../Redux/Actions/CommonActions";
import Swal from "sweetalert2";
import { FaSpinner } from "react-icons/fa";
import { BiEditAlt } from "react-icons/bi";
import { Calendar } from "primereact/calendar";
import { Dropdown } from 'react-bootstrap';
import { MdOutlineEdit } from "react-icons/md";


function PersonalDetailsTabs() {

  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [options, setOptions] = useState([]); // State for options
  const [gender, setGender] = useState(auth.user.gender)

  const [editInfo, setEditInfo] = useState(auth?.user)
  const [loader, setLoader] = useState({
    user: false,
    image: false,
    save: false
  })
  const [selectedValues, setSelectedValues] = useState(JSON.parse(auth?.user?.language));
  useEffect(() => {
    dispatch(fetchData("get_languages", {}, (data) => {
      setOptions(data.map(lang => ({ label: lang.name, value: lang.id })));
    }, loader, setLoader, false, "shortDataLg"));
    dispatch(loadUser())
  }, [dispatch])

  const handleChange = (e) => {
    setSelectedValues(e.value);


  };
  const calculateAge = (dob) => {
    if (!dob) return '';
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();

    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;

  };
  const onImageChange = (e) => {
    if (e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/jpeg') {
      if (e.target.files[0].size < 5000000) {
        const formData = new FormData()
        formData.append('user_id', auth.user.id);
        formData.append('image', e.target.files[0]);
        setLoader({
          ...loader,
          image: true
        })
        dispatch(changeProfile(formData, loader, setLoader))

      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Max allowed size is 5MB!',
          confirmButtonColor: "#000"
        })
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You have selected invalid file type!',
        confirmButtonColor: "#000"
      })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let formData = {
      ...editInfo,
      language: selectedValues,
      "gender": gender,
      "age": calculateAge(editInfo.dob)
    }
    setLoader({
      ...loader,
      save: true
    })

    dispatch(userInformation(formData, setLoader, loader, handleClose))

  }
  // const handleAllAccountLogOut = () => {

  //   dispatch(logoutAllDevice())
  // }
  return (
    <>
      {/* <div className="alert-box dark mb-4">
        <div className="alert-icon"><AiOutlineExclamationCircle /></div>
        <div className="alert-txt">
          <h6>We need your attention!</h6>
          <p>You don’t have any payment method added yet. To start receiving withdrawls, please <a href="">Add Payment Method</a>.</p>
        </div>
      </div> */}
      <div className="account-block relative">


        {

          loader.image ?
            <div className="accountPic">
              <div className="d-flex align-items-center justify-content-center" style={{ height: "100%", fontSize: 20 }}>
                <FaSpinner className="spin" />
              </div>
            </div>
            :
            <>
              <div className="accountPic">
                <img src={auth.user.profile || "https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg"} alt="" />
              </div>
              <label htmlFor="upload-img" className="account-edit-btn">
                <input type="file" name="" id="upload-img" onChange={onImageChange} />
                <BiEditAlt style={{ fill: "#fff" }} />
              </label>
            </>
        }

        {/* <img src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg" alt="" /> */}

        {/* <span className="account-edit-btn">
              
              <input type="file" name="" id="" onChange={onImageChange}/>
              <BiEditAlt />
              </span> */}

        <div className="contInfo">
          <h5>{auth?.user?.name ? auth?.user?.name : "-"}</h5>
          <ul>



            <li><HiOutlineEnvelope /> <span>{auth?.user?.email ? auth?.user?.email : "-"}</span></li>
          </ul>
        </div>
        <div className="contStat">
          <ul>
            <li>
              <h6>Profile Completion</h6>
              <div className="progressBar mt-2">
                <span className="progressBar-mark" style={{ width: auth?.user?.profile_compleation }}></span>
                <span className="progressBar-num">{auth?.user?.profile_compleation}%</span>
              </div>
            </li>
            <li>
              <h6>Spending</h6>
              <h5>{auth?.user?.earning ? auth?.user?.earning : "-"}</h5>
            </li>
            <li>
              <h6>Tasks</h6>
              <h5>{auth?.user?.task}</h5>
            </li>
            <li>
              <h6>Approval Rate</h6>
              <h5>{auth?.user?.success_rate}%</h5>
            </li>
          </ul>
        </div>
      </div>
      <div className="account-block mt-4">
        <div className="d-flex align-items-center justify-content-between">
          <h6>Profile Details</h6>
          <a onClick={handleShow} style={{ cursor: "pointer" }}><MdOutlineEdit className="me-1" />
            Edit Profile</a>
        </div>
        <div className="profileDetails">
          <ul>
            <li><span>Name</span><span>{auth?.user?.name ? auth?.user?.name : "-"}</span></li>
            <li><span>Phone number <span className="tooltipN"><AiOutlineExclamationCircle /><span className="tooltiptext">Tooltip text</span></span></span><span>{auth?.user?.mobileNumber}</span><span className="verBadge">Verified</span></li>
            <li><span>Email</span><span>{auth?.user?.email}</span></li>

            <li>
              <span>Language</span>
              <span>{auth.user.language_name ? auth.user.language_name.map((curElem, i) => {

                return (
                  <span>{curElem?.name}</span>
                )
              }) : "-"}</span></li>
            <li><span>Age</span><span>{auth?.user?.age ? auth?.user?.age : "-"}</span></li>
            <li><span>Gender</span><span>{auth?.user?.gender ? auth?.user?.gender : "-"}</span></li>
            <li><span>Dob</span><span>{auth?.user?.dob ? auth?.user?.dob : "-"}</span></li>

          </ul>
        </div>

      </div>
      {/* <div className="account-block mt-4 mb-5">
        <h6 className="pb-3">Security</h6>
        <div className="supportBlock" onClick={handleAllAccountLogOut}>
          <h6>Log out of all devices</h6>
          <p>Log out of all other active sessions on other devices besides this one.</p>
        </div>
        <div className="supportBlock">
          <h6 className="txt-red">Delete my account</h6>
          <p>Permanently delete the account and remove access from all devices.</p>
        </div>
      </div> */}

      <Modal className="modalRights theme-modal custom-inputs" show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Body className="pt-0">
          <Modal.Header closeButton style={{ borderBottom: "0" }}>
          </Modal.Header>
          <div className='modalTitle ps-0 pb-3' style={{ height: "unset" }}>
            <h6>Edit Profile Details</h6>
          </div>

          <div>
            <form onSubmit={handleSubmit}>
              {/* <h6 className='pt-4'>{editInfo.name}</h6> */}
              <div className="input-wrap alt">
                <label className="label mb-2" htmlFor="">Name</label>
                <input className="input" type="text" name="name" id="" value={editInfo.name} onChange={(e) => setEditInfo({ ...editInfo, "name": e.target.value })} />
              </div>
              <div className="input-wrap alt">
                <label className="label mb-2" htmlFor="">Gender</label>
                <Dropdown>
                  <Dropdown.Toggle variant="" className="p-0 d-flex align-items-center w-100 border-0 justify-content-start" id="dropdown-basic-one">
                    {gender}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="w-100">
                    <Dropdown.Item onClick={() => setGender("Male")}>Male</Dropdown.Item>
                    <Dropdown.Item onClick={() => setGender("Female")}>Female</Dropdown.Item>
                    <Dropdown.Item onClick={() => setGender("Other")}>Other</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </div>


              <div className="input-wrap alt">
                <label className="label mb-2" htmlFor="">Date of Birth</label>
                {/* <div className='inp-with-icon'>
                  <input className="input" type="text" name="dob" id="" value={editInfo.dob} onChange={(e) => setEditInfo({ ...editInfo, "dob": e.target.value })} />
                  <span className='inp-icon'><IoCalendarClearOutline /></span>
                </div> */}
                <div className="flex-auto">
                  {/* <label htmlFor="buttondisplay" className="font-bold block mb-2">
                    Button Display
                </label> */}
                  <Calendar id="buttondisplay" value={editInfo.dob} onChange={(e) => setEditInfo({ ...editInfo, "dob": e.target.value })} showIcon />

                </div>
              </div>
              {/* <div className="input-wrap alt">
                <label className="label mb-2" htmlFor="">Age (Year) </label>
                <input className="input " type="tel" name="age" id="" value={calculateAge(editInfo.dob)}
                  readOnly />
              </div> */}

              <div className="input-wrap alt mb-4">
                <label className="label mb-2" htmlFor="">Langauge</label>
                <MultiSelect
                  className="multi-select"
                  value={selectedValues}
                  options={options}
                  onChange={handleChange}
                  optionLabel="label"
                  placeholder="Select Language"
                  display="chip"
                />
              </div>

              <div className="modal-button-bottom">
                <button className="theme-btn bdr" type="button" onClick={handleClose}>Cancel</button>
                <button className="theme-btn" type="submit">{loader.save ? <FaSpinner style={{ fontSize: "15px" }} className="spin ms-1" /> : ""}Save</button>
              </div>
            </form>
          </div>

        </Modal.Body>
      </Modal>

    </>
  )
}

export default PersonalDetailsTabs;