import React, { useState } from 'react'
import { Modal } from "react-bootstrap";
import { ScrollPanel } from "primereact/scrollpanel";
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { useDispatch } from 'react-redux';
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { ChevronRightIcon } from 'primereact/icons/chevronright';
import { Dropdown } from 'primereact/dropdown';
import { FaSpinner } from 'react-icons/fa6';
import { addAdminuserAction } from '../../../Redux/Actions/AdminSettingsActions';
import PhoneNumber from '../../Auth/PhoneNumber';

const AddNewUsersModal = (props) => {
  const [selectedType, setSelectedType] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState({ name: 'India', code: 'IN', phoneCode: '+91' });
  const [countries, setCountries] = useState([])

  const dispatch = useDispatch()

  const [addUser, setAddUser] = useState({
    user: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",

  })
  const cities = [
    { name: 'Admin', code: 'Ad', value: "admin" },
    { name: 'Creater', code: 'Cr', value: "Creater" },
    { name: 'Tasker', code: 'Ta', value: "Tasker" }
  ];
  const regEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const regpass = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{8,16}$/;

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="d-flex align-items-center ">
          <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
          <div className='ms-2'>{option.name} </div>

        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="d-flex align-items-center ">
        <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
        <div className='ms-2'>{option.name}  ({option.phoneCode})</div>
      </div>
    );
  };

  const panelFooterTemplate = () => {
    return (
      <div className="py-2 px-3">
        {selectedCountry ? (
          <span>
            <b>{selectedCountry.name}</b> selected.
          </span>
        ) : (
          'No country selected.'
        )}
      </div>
    );
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddUser({ ...addUser, [name]: value })

  }
  const handleSubmit = (e) => {
    e.preventDefault()

    if (regpass.test(addUser.password) === false) {
      dispatch(setAlert("Use 8 or more characters with a mix of letters, numbers & symbols for the password field. ", "danger"));

    } else if (regEmail.test(addUser.email) === false) {
      dispatch(setAlert("Incorrect Email", "danger"))
    }
    else if (addUser.phoneNumber.length < 10) {

      dispatch(setAlert("Please enter a valid 10-digit mobile number", "danger"))
    } else if (addUser.password !== addUser.confirmPassword) {
      dispatch(setAlert("Password and confirm password is not equal", "danger"))

    } else if (selectedType === null) {
      dispatch(setAlert("Please select user type", "danger"))
    } else if (selectedCountry === null) {
      dispatch(setAlert("Please select country ", "danger"))
    }
    else {
      props.setLoader({
        ...props.loader,
        addUser: true
      })
      let data = {
        "name": addUser?.user,
        "email": addUser?.email,
        "password": addUser?.password,
        "userType": selectedType,
        "mobileNumber": selectedCountry?.phoneCode + addUser?.phoneNumber
      }
      dispatch(addAdminuserAction(data, props.setLoader, props.loader, props.handleClose, props.fetchData))

    }
  }

  return (
    <Modal className="modalRights theme-modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>
      <Modal.Header closeButton style={{ borderBottom: "0" }}>
      </Modal.Header>
      <Modal.Body className='pt-0'>
        <div className='modalTitle ps-0 pb-2 mb-3' style={{ height: "unset" }}>
          <h6>Create New User</h6>
        </div>

        <ScrollPanel className="modalScroll ps-0">
          <form onSubmit={handleSubmit}>
            {/* <h6 className='pt-4'>Mehedi Hasan</h6> */}
            <div className="input-wrap alt">
              <label className="label mb-2" htmlFor="">Name</label>
              <input className="input" type="text" name="user" placeholder='Name' value={addUser.user} onChange={handleChange} required />
            </div>
            <div className="input-wrap alt">
              <label className="label mb-2" htmlFor="">Email</label>
              <input className="input" type="email" name="email" placeholder='Email' value={addUser.email} onChange={handleChange} required />
            </div>
            <div className="input-wrap alt">
              <label className="label mb-2" htmlFor="">Password</label>
              <input className="input" type="password" name="password" placeholder='Password' value={addUser.password} onChange={handleChange} />
            </div>
            <div className="input-wrap alt">
              <label className="label mb-2" htmlFor="">Confirm password</label>
              <input className="input" type="password" name="confirmPassword" placeholder='Confirm password' value={addUser.passconfirmPasswordword} onChange={handleChange} />
            </div>
            <div className="input-wrap alt">
              <label className="label mb-2" htmlFor="">Select country</label>
              <Dropdown
                value={selectedCountry}
                onChange={(e) => setSelectedCountry(e.value)}
                options={countries}
                optionLabel="name"
                placeholder="Country"
                valueTemplate={selectedCountryTemplate}
                itemTemplate={countryOptionTemplate}
                className="w-full md:w-14rem"
                panelFooterTemplate={panelFooterTemplate}
                dropdownIcon={(opts) => {
                  return opts.iconProps['data-pr-overlay-visible'] ? <ChevronRightIcon {...opts.iconProps} /> : <ChevronDownIcon {...opts.iconProps} />;
                }} />
            </div>
            <div className="input-wrap alt">
              <label className="label mb-2" htmlFor="">Phone number</label>
              <input className="input" type="tel" name="phoneNumber" placeholder='Phone number' value={addUser.phoneNumber} onChange={(e) => {
                const newValue = e.target.value.replace(/[^0-9]/g, '');
                setAddUser({ ...addUser, phoneNumber: newValue });
              }}
                maxLength={10}
                pattern="[0-9]*"
                inputMode="numeric" />
            </div>
            <div className="input-wrap alt mb-4">
              <label className="label" htmlFor="">Select Role</label>
              <PrimeDropdown value={selectedType} onChange={(e) => setSelectedType(e.value)} options={cities} optionLabel="name"
                placeholder="Select a Type" className="input ps-0 p-1" />
            </div>

            <div className="modal-button-bottom mt-5">
              <button className="theme-btn bdr" type="button" onClick={props.handleClose}>Cancel</button>
              <button className="theme-btn" type="submit">Add{props.loader.addUser ? <FaSpinner className='spin ms-1' /> : ""}</button>
            </div>
          </form>
          <PhoneNumber setCountryInput={setCountries} />
        </ScrollPanel>
      </Modal.Body>
    </Modal>
  )
}

export default AddNewUsersModal
