import React, { useEffect, useState } from "react";
import { PiWarningCircleBold } from "react-icons/pi";
import SidePanel from "../../../Common/SidePanel";
import DashboardHeader from "../../../Common/DashboardHeader";
import { ScrollPanel } from "primereact/scrollpanel";
import AboutClient from "./AboutClient";
import { Nav, Tab } from 'react-bootstrap';
import PayoutRequestModal from "../../ModalComponents/PayoutRequestModal";
import AvailableTasks2 from "./TaskModuleComponent/AvailableTasks2";
import TaskSubmitModal from "../../ModalComponents/TaskSubmitModal";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TaskTaskerDetails } from "../../../../Redux/Actions/TaskTaskerActions";
import { FaSpider, FaSpinner } from "react-icons/fa";

const TaskDetails = () => {
    const [show, setShow] = useState(false);
    const dispatch = useDispatch()
    const [taskDetails, setTaskDetails] = useState('')
    const [loader, setLoader] = useState({
        create: false,

    })
    const location = useLocation()
    const slugId = location.state.slug



    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    useEffect(() => {
        let formData = {
            "slug": slugId
        }
        setLoader({
            create: true
        })
        dispatch(TaskTaskerDetails(formData, setTaskDetails, setLoader))
    }, [slugId])

    return (
        <>

            <div className="dashboard container-fluid overflow-hidden">

                <div className="row">

                    <div className="col-lg-2 col-md-3 col-sm-4 col-12">
                        <SidePanel />
                    </div>
                    <div className="col-lg-10 col-md-9 col-sm-8 col-12 p-0">
                        <div>
                            <div className="row">
                                <div className=" col-xl-9 col-lg-8 ps-0 pe-0">
                                    <div className="dashboard-in main_task_wrapper">

                                        <DashboardHeader />

                                        <ScrollPanel className="custombar2 content-area">
                                            <div className="main_task_details p-4">
                                                <div className="do_task_wrapper ">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                                                <div className="row" >
                                                                    <div className="col-12 mb-3">
                                                                        <Nav variant="pills" style={{ padding: "10px 28px 0px 6px" }} >
                                                                            <Nav.Item>
                                                                                <Nav.Link eventKey="first" > Task Details</Nav.Link>
                                                                            </Nav.Item>
                                                                            <Nav.Item>
                                                                                {/* <Nav.Link eventKey="second">My Submission</Nav.Link> */}
                                                                            </Nav.Item>
                                                                        </Nav>
                                                                    </div>

                                                                    <div className="col-12">
                                                                        <div className="setting_right_side_wrap">
                                                                            <Tab.Content>
                                                                                <Tab.Pane eventKey="first">
                                                                                    {

                                                                                        loader.create ? <div className="d-flex align-items-center justify-content-center mt-4 " style={{ height: "100vh" }}><FaSpinner className="spin custom-spin ms-1" style={{ fontSize: "40px" }} /></div> : <>

                                                                                            <div className="col-12 ">
                                                                                                <div className="account-block mt-2">
                                                                                                    <h6>Comment on my Instagram Reel</h6>

                                                                                                    <div className="d-flex task_main_wrap  ">
                                                                                                        <div className="task_process">
                                                                                                            <span>Status</span>
                                                                                                            {/* <div className="task_process_bar">
                                                                        <p>Progress <span>/</span> 51%</p>
                                                                    </div> */}
                                                                                                            <div className="progress_wrap" style={{ position: "relative" }}>
                                                                                                                <h3>{taskDetails.status === "1" ? <>InProgress</> : taskDetails.status === "2" ? <>Complete</> : <>Inactive</>}</h3>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                        <div className="task_process ps-3 pe-3">
                                                                                                            <span className="text-center w-100">Total Submission</span>
                                                                                                            <div className="task_process_bar text-center">
                                                                                                                <p>{taskDetails.total_submission}%</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="task_process ps-3 pe-3">
                                                                                                            <span className="text-center w-100">Due Date</span>
                                                                                                            <div className="task_process_bar text-center">
                                                                                                                <p>{taskDetails.due_date}</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="task_process ps-3 pe-3 " style={{ border: "0" }}>
                                                                                                            <span className="text-center w-100">Price</span>
                                                                                                            <div className="task_process_bar text-center">
                                                                                                                <p>₹{taskDetails.price}</p>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="account-block  mt-4">
                                                                                                <div className="task_border pb-2">
                                                                                                    <h6>Tasks Overview</h6>
                                                                                                    <div className="task_overview_sec">
                                                                                                        <h3>{taskDetails.title} ( #TSK000{taskDetails.id} )</h3>
                                                                                                        {/* <p>
                                                                                                            We are seeking a talented Social Media Manager with a knack for creating engaging and captivating Instagram Reels. The ideal candidate will have a strong understanding of social media trends, content creation, and audience engagement. This role involves managing our social media accounts, developing content strategies, and interacting with our followers to build.<br />
                                                                                                            Link : <a href="#">https://www.instagram.com/dotask6/</a>
                                                                                                        </p> */}
                                                                                                        <div
                                                                                                            dangerouslySetInnerHTML={{ __html: taskDetails?.description }}
                                                                                                        />
                                                                                                        {/* <p>{taskDetails?.description}</p> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                {/* <div className="task_border mt-3 pb-2">
                                                                                                    <h6 className="mt-3">Instructions</h6>
                                                                                                    <ol>
                                                                                                        <li>Develop and implement social media strategies to increase brand awareness and engagement</li>
                                                                                                        <li>Create, edit, and post engaging content, including Instagram Reels, stories, and posts.</li>
                                                                                                        <li>Manage day-to-day social media activities across all platforms (Instagram, Facebook, Twitter, etc.).</li>
                                                                                                        <li>Monitor social media trends and incorporate relevant trends into content planning.</li>
                                                                                                        <li>Respond to comments and messages in a timely and professional manner.</li>
                                                                                                    </ol>
                                                                                                </div>
                                                                                                <div className="warning_wrap mt-4">
                                                                                                    <div className="warning_icon">
                                                                                                        <PiWarningCircleBold />
                                                                                                    </div>
                                                                                                    <div className="warning_para">
                                                                                                        <h3>We need your attention!</h3>
                                                                                                        <p>Make sure you’re submitting the task with proper instruction followed. Non-compliant submissions may ban you account permanently.</p>

                                                                                                    </div>
                                                                                                </div> */}
                                                                                                <div className="col-12 mt-5 text-center mb-3">
                                                                                                    <button className="theme-btn light" onClick={handleShow}> Start Task </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>



                                                                                    }

                                                                                </Tab.Pane>

                                                                                <Tab.Pane eventKey="second">
                                                                                    {/* <AvailableTasks2 /> */}
                                                                                </Tab.Pane>
                                                                                <Tab.Pane eventKey="third">
                                                                                </Tab.Pane>

                                                                            </Tab.Content>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Tab.Container >
                                                        </div>



                                                    </div>
                                                </div>




                                            </div>



                                        </ScrollPanel>

                                    </div>
                                </div>
                                <div className=" col-xl-3 col-lg-4 ps-0">

                                    <AboutClient aboutClient={taskDetails} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TaskSubmitModal
                show={show}
                handleClose={handleClose}
                slugId={slugId}
            />
            {/* <PayoutRequestModal
                show={show}
                handleClose={handleClose}
            /> */}
        </>
    )
}

export default TaskDetails
