import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';

import './App.scss';
import './css/flag.scss';
import './css/custom.scss';
import './css/dev.scss';
import { ThemeProvider } from "./Components/Theme-Context/ThemeContext";
import SignIn from "./Components/Auth/SignIn";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import Alert from "./Components/Common/Alert";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeAlert } from "./Redux/Actions/AlertActions";
import PrivateRoute from "./Components/Common/PrivateRoute";
import ResetPassword from "./Components/Auth/ResetPassword";
import MasterLogin from "./Components/Auth/MasterLogin";
import Dashboard from "./Components/Tasker/TaskerDashboard/Dashboard";
import Task from "./Components/Tasker/TaskModule/Task";
import TaskDetails from "./Components/Tasker/TaskModule/TaskDetails/TaskDetails";
import ProfileSettings from "./Components/Tasker/Profile-Settings/ProfileSettings";
import Achievements from "./Components/Tasker/Achievements/Achievements";
import Referral from "./Components/Tasker/Referral/Referral";
import VerificationFirstStep from "./Components/Tasker/Phone-Number/VerificationFirstStep";
import VerificationSecondStep from "./Components/Tasker/Phone-Number/VerificationSecondStep";
import VerificationThirdStep from "./Components/Tasker/Phone-Number/VerificationThirdStep";
import VerificationFourthStep from "./Components/Tasker/Phone-Number/VerificationFourthStep";
import VerificationFifthStep from "./Components/Tasker/Phone-Number/VerificationFifthStep";
import VerificationSixthStep from "./Components/Tasker/Phone-Number/VerificationSixthStep";
import AccountSelector from "./Components/Auth/AccountSelector";
import SignUp from "./Components/Auth/SignUp";
import Verification from "./Components/Auth/Verification";
import ProfileSettingsTaskCreator from "./Components/TaskCreator/Profile-Settings/ProfileSettingsTaskCreator";
import ProfileSettingsAdmin from "./Components/Admin/Profile-Settings/ProfileSettingsAdmin";
import ReviewsTaskCreator from "./Components/TaskCreator/Reviews/ReviewsTaskCreator";
import SpendingTaskCreator from "./Components/TaskCreator/Spending/SpendingTaskCreator";
import Earnings from "./Components/Tasker/EarningsPayouts/Earnings";
import CreatorDashboard from "./Components/TaskCreator/CreatorDashboard/CreatorDashboard";
import CreatorTask from "./Components/TaskCreator/CreatorTask/CreatorTask";
import CreateNewTask from "./Components/TaskCreator/CreatorTask/CreateNewTask";
import CreatorNavDetails from "./Components/TaskCreator/CreatorTask/CreatorTaskDetails/CreatorNavDetails";
import ProfileDetailsAdmin from "./Components/Admin/Profile-Details/ProfileDetailsAdmin";
import AdminDashboard from "./Components/Admin/AdminDashboard/AdminDashboard";
import NavTaskManager from "./Components/Admin/ManagementTask/NavTaskManager";
import AdminNavProfile from "./Components/Admin/AdminTaskCreatorProfile/AdminNavProfile";
import AdminNavTaskDetails from "./Components/Admin/AdminTaskCreatorProfile/AdminTaskDeatils/AdminNavTaskDetails";
import MobileVerification from "./Components/Auth/MobileVerification";
import MangerUser from "./Components/Admin/AdminTaskCreatorProfile/MangerUser";
import ApproveNavPayout from "./Components/Admin/ApprovePayout/ApproveNavPayout";
import PrivacyPolicy from "./Components/Common/PrivacyPolicy";
import TermsOfServices from "./Components/Common/TermsOfServices";
import Disclaimer from "./Components/Common/Disclaimer";
import Privacy from "./Components/Privacy/Privacy";
import Help from "./Components/Help/Help";
import CreateAmount from "./Components/TaskCreator/Payments/CreateAmount";
import HelpDetails from "./Components/Help/HelpDetails";




function App() {
  const dispatch = useDispatch()
  const alert = useSelector(state => state.alert)

  useEffect(() => {
    if (alert !== undefined) {
      if (alert.message !== "") {
        setTimeout(() => {
          dispatch(removeAlert())
        }, 4000);
      }
    }
  }, [alert, dispatch])

  return (
    <ThemeProvider>
      <Alert />
      <div className="App">
        <Router>
          {/* <ScrollTop/> */}
          <Routes>

            <Route path="/account-selector" element={<AccountSelector />} />
            <Route path="/" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/verification" element={<Verification />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/master-login" element={<MasterLogin />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route exact path="/mobile-verification" element={<MobileVerification />} />
            <Route exact path="/dashboard" element={<PrivateRoute><Dashboard /> </PrivateRoute>} />
            <Route exact path="/tasks" element={<PrivateRoute><Task /> </PrivateRoute>} />
            <Route exact path="/task-details" element={<PrivateRoute><TaskDetails /> </PrivateRoute>} />
            <Route exact path="/help-details" element={<PrivateRoute><HelpDetails /> </PrivateRoute>} />
            <Route exact path="/profile-settings" element={<PrivateRoute><ProfileSettings /> </PrivateRoute>} />
            <Route exact path="/task-creator/profile-settings" element={<PrivateRoute><ProfileSettingsTaskCreator /> </PrivateRoute>} />
            <Route exact path="/admin/profile-settings" element={<PrivateRoute><ProfileSettingsAdmin /> </PrivateRoute>} />

            <Route exact path="/achievements" element={<PrivateRoute><Achievements /> </PrivateRoute>} />
            <Route exact path="/task-creator/reviews" element={<PrivateRoute><ReviewsTaskCreator /> </PrivateRoute>} />
            <Route exact path="/task-creator/spending" element={<PrivateRoute><SpendingTaskCreator /> </PrivateRoute>} />


            <Route exact path="/admin/profile-details" element={<PrivateRoute><ProfileDetailsAdmin /> </PrivateRoute>} />


            <Route exact path="/referral" element={<PrivateRoute><Referral /> </PrivateRoute>} />
            <Route exact path="/earnings" element={<PrivateRoute><Earnings /> </PrivateRoute>} />
            <Route exact path="/step-1" element={<PrivateRoute><VerificationFirstStep /> </PrivateRoute>} />
            <Route exact path="/step-2" element={<PrivateRoute><VerificationSecondStep /> </PrivateRoute>} />
            <Route exact path="/step-3" element={<PrivateRoute><VerificationThirdStep /> </PrivateRoute>} />
            <Route exact path="/step-4" element={<PrivateRoute><VerificationFourthStep /> </PrivateRoute>} />
            <Route exact path="/step-5" element={<PrivateRoute><VerificationFifthStep /> </PrivateRoute>} />
            <Route exact path="/step-6" element={<PrivateRoute><VerificationSixthStep /> </PrivateRoute>} />
            <Route exact path="/creator/dashboard" element={<PrivateRoute><CreatorDashboard /> </PrivateRoute>} />
            <Route exact path="/creator/task" element={<PrivateRoute><CreatorTask /> </PrivateRoute>} />
            <Route exact path="/create/task" element={<PrivateRoute><CreateNewTask /> </PrivateRoute>} />
            <Route exact path="/creator/details" element={<PrivateRoute><CreatorNavDetails /> </PrivateRoute>} />
            <Route exact path="/admin/dashboard" element={<PrivateRoute><AdminDashboard /> </PrivateRoute>} />
            <Route exact path="/admin/all-task" element={<PrivateRoute><NavTaskManager /> </PrivateRoute>} />
            <Route exact path="/admin/tasker/profile" element={<PrivateRoute><AdminNavProfile /> </PrivateRoute>} />
            <Route exact path="/admin/Task/details" element={<PrivateRoute><AdminNavTaskDetails /> </PrivateRoute>} />
            <Route exact path="/admin-manger-user" element={<PrivateRoute><MangerUser /> </PrivateRoute>} />
            <Route exact path="/admin/nav/payout" element={<PrivateRoute><ApproveNavPayout /> </PrivateRoute>} />
            <Route exact path="/privacy-nav" element={<PrivateRoute>< Privacy /> </PrivateRoute>} />
            <Route exact path="/Help" element={<PrivateRoute>< Help /> </PrivateRoute>} />
            <Route exact path="/privacy" element={<PrivacyPolicy />} />
            <Route exact path="/Termsofservices" element={<TermsOfServices />} />
            <Route exact path="/disclaimer" element={<Disclaimer />} />

            <Route exact path="/amountpage" element={<PrivateRoute>< CreateAmount /> </PrivateRoute>} />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
