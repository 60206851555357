import React, { useState } from 'react';
import { ScrollPanel } from 'primereact/scrollpanel';

import SidePanel from "../../Common/SidePanel";
import DashboardHeader from "../../Common/DashboardHeader";
import DashboardFooter from "../../Common/DashboardFooter";
import NotificationBar from "../../Common/NotificationBar";

import { IoCopyOutline } from "react-icons/io5";
import { CiCalendar } from "react-icons/ci";

function Referral() {

  const [isActive, setIsActive] = useState(false);
  const toggleClass = () => {
    setIsActive(!isActive);
  };

  const [isActive2, setIsActive2] = useState(false);
  const toggleNotification = () => {
    setIsActive2(!isActive2);
  };

    return (
        <>
            <div className={isActive ? 'dashboard dashboard-modified activeSidePanel' : 'dashboard dashboard-modified'}>
                <SidePanel />
                <div className="dashboard-in active-right-panel">
                    <DashboardHeader toggleSidebar={toggleClass} notificationToggle={toggleNotification} />
                    <ScrollPanel className="custombar2 content-area">
                        <div className="content-area-in">
                            <div className="account-block relative">
                                <h5>Referral Program</h5>
                                <div className="contStat">
                                    <ul>
                                        <li>
                                            <h6>Net Earnings</h6>
                                            <h5>$6,840</h5>
                                        </li>
                                        <li>
                                            <h6>Balance</h6>
                                            <h5>$8,530</h5>
                                        </li>
                                        <li>
                                            <h6>Total Joined</h6>
                                            <h5>5200</h5>
                                        </li>
                                        <li>
                                            <h6>Referral Signups</h6>
                                            <h5>$783</h5>
                                        </li>
                                    </ul>
                                </div>
                                <hr />

                                <div className="input-style-wrap">
                                    <h6 className="pb-2">Your Referral Link</h6>
                                    <div className="input-icon-wrap">
                                        <input type="text" placeholder="https://google.com/asbkqworh123DDDAAkjsdhf" className="input-style sm" />
                                        <span className="input-icon"><IoCopyOutline /></span>
                                    </div>
                                    <p className="input-info pt-2"><small>Plan your blog post by choosing a topic, creating an outline conduct research, and checking facts.</small></p>
                                </div>

                                <hr />

                                <div>
                                    <div className="row align-items-center">
                                        <div className="col-md">
                                            <h6 className="fs-14">How to use Referral Program</h6>
                                            <p className="input-info"><small>Use images to enhance your post, improve its flow, add humor and explain complex topics.</small></p>
                                        </div>
                                        <div className="col-md-auto pt-3 pt-md-0">
                                            <button className="theme-btn w-100 light">Get Started</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="infoBlock mt-4">
                                    <div className="row align-items-center">
                                        <div className="col-md">
                                            <div className="row m-0 gap-2">
                                                <div className="col-auto p-0"><img src={require("../../../images/icon/dollar.svg").default} alt="" /></div>
                                                <div className="col p-0">
                                                    <h6 className="fs-14">Withdraw Your Money to a Bank Account</h6>
                                                    <p className="input-info"><small>Withdraw money securily to your bank account. Commision is $25 per transaction under $50,000.</small></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-auto pt-3 pt-md-0">
                                            <button className="theme-btn w-100 light">Withdraw Money</button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="account-block mt-4">
                                <div className="row">
                                    <div className="col">
                                        <h6>Referred Users</h6>
                                    </div>
                                    <div className="col-auto">
                                        <ul className="d-flex align-items-center gap-3 year-select">
                                            <li className="active">This Year</li>
                                            <li>2022</li>
                                            <li>2021</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="table-responsive mt-4">
                                    <table className="table theme-table">
                                        <tr>
                                            <th>Refer ID</th>
                                            <th>Manager</th>
                                            <th>Date</th>
                                            <th>Bonus</th>
                                            <th className="text-end">Profit</th>
                                        </tr>
                                        <tr>
                                            <td>678935899</td>
                                            <td><span className="userImg"><img src="https://images.pexels.com/photos/2787341/pexels-photo-2787341.jpeg" alt="" /></span> Natali Craig</td>
                                            <td><CiCalendar /> Feb 25, 2023</td>
                                            <td>26%</td>
                                            <td className="text-end">$1,200.00</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </ScrollPanel>
                    <DashboardFooter />
                </div>

                <div className={isActive2 ? 'notificationActive' : ''}>
                    <NotificationBar />
                </div>
                
            </div>
            
        </>
    )
}

export default Referral;