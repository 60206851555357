import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import AdminTaskDetails from './AdminTaskDetails'
import SidePanelAdmin from '../../../Common/SidePanelAdmin'
import DashboardHeader from '../../../Common/DashboardHeader'
import AdminApplication from './AdminApplication'
import AdminSubmissions from './AdminSubmissions'
import AdminReviews from '../AdminReviews'
import { useLocation } from 'react-router-dom'

const AdminNavTaskDetails = () => {
   
    return (
        <>
            <div className="dashboard creator_new_task container-fluid overflow-hidden">
                <div className="row">

                    <div className="col-lg-2 col-md-3 col-sm-4 col-12 pe-0">
                        <SidePanelAdmin />
                    </div>
                    <div className="col-lg-10 col-md-9 col-sm-8 col-12 pt-0 pb-0 ps-0">
                        <div className="dashboard-in  ">

                            <DashboardHeader />
                            <div className="row">
                                <div className="col-12">
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                        <Nav variant="pills" className="p-3">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">Task Details</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">Applications</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third"> Submissions</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="forth">Reviews</Nav.Link>
                                            </Nav.Item>

                                        </Nav>

                                        <div className="setting-right-side-wrap admin-custom-footer">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <AdminTaskDetails />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <AdminApplication />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="third">
                                                    <AdminSubmissions />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="forth">
                                                    {/* <AdminReviews /> */}
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>
                                    </Tab.Container>

                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div >


        </>
    )
}

export default AdminNavTaskDetails
