import React, { useState,useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { masterLoginUser } from '../../Redux/Actions/AuthActions'
import Footer from '../Common/Footer'
import { FaSpinner } from 'react-icons/fa'
import { IoEyeOffOutline } from 'react-icons/io5'
import TitleBar from '../Common/TitleBar'

const MasterLogin = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth)
    const [view, setView] = useState(false)
    const [loader, setLoader] = useState(false)
    
    const [loginInfo, setLoginInfo] = useState({
      email: "",
      password: "",
      userType:"admin"
    })
  
    useEffect(() => {
      if (auth.isAuthenticated) {
        if(auth.user.role==="admin"){
          navigate('/admin/dashboard')
        }
        
        
      }
    }, [auth])
  
  
    const handleChange = (e) => {
      const { name, value } = e.target
      setLoginInfo({
        ...loginInfo,
        [name]: value
      })
    }
  
    const handleSubmit = (e) => {
      e.preventDefault()
    //  navigate("/admin/dashboard")
      setLoader(true)
      dispatch(masterLoginUser(loginInfo, setLoader,navigate))
    }
  
    return (
        // <>
        //     <TitleBar title="Master Login" />
        //     {/* <div className="login-head-wrap">
        //         <div className="login-head">
        //             <div className="login-logo"><img src={require("../../images/logo.svg").default} alt="" /></div>
        //         </div>
        //     </div> */}

        //     <div className="login-wrap">
        //         {/* <div className="login-left">
        //             <h2 className="pb-3">Welcome To AiCalling</h2>
        //             <p>Instantly turn any webpage into a traffic-getting <br /> video at the push of a button!</p>
        //         </div> */}
        //         <form className="login-right" onSubmit={handleSubmit}>
        //             <div className="login-right-main">
        //                 <h3>Master Login to AiCalling</h3>
        //                 <div className="login-input-wrap">
        //                     <label htmlFor="">User Email Address</label>
        //                     <input
        //                         className="login-input"
        //                         type="email"
        //                         name="useremail"
        //                         placeholder='User Email Address'
        //                         value={loginInfo.useremail}
        //                         onChange={handleChange}
        //                         required
        //                     />
        //                 </div>
        //                 <div className="login-input-wrap">
        //                     <label htmlFor="">Admin Email Address</label>
        //                     <input
        //                         className="login-input"
        //                         type="email"
        //                         placeholder="Admin Email Address"
        //                         value={loginInfo.email}
        //                         onChange={handleChange}
        //                         name="email"
        //                         required
        //                     />
        //                 </div>
        //                 <div className="login-input-wrap">
        //                     <label htmlFor="">Password</label>
        //                     <div className="input-logo">
        //                         <input
        //                             className="login-input"
        //                             type={view ? "text" : "password"}
        //                             placeholder="Password"
        //                             required
        //                             onChange={handleChange}
        //                             value={loginInfo.password}
        //                             name="password"
        //                         />
        //                         <span
        //                             onMouseUp={() => setView(false)}
        //                             onMouseLeave={() => setView(false)}
        //                             onMouseDown={() => setView(true)}
        //                             className="inp-icon"
        //                         >
        //                             <IoEyeOffOutline />
        //                         </span>
        //                     </div>
        //                 </div>
        //                 <div className="login-input-wrap">
        //                     <button type="submit" className="login-btn ">Sign In {loader ? <FaSpinner className="spin ms-1" /> : null}</button>
        //                 </div>
        //                 <div className="login-input-wrap text-center">
        //                     <p>Forgot your account details? <Link to="/forgot-password">Click here</Link></p>
        //                 </div>
        //             </div>
        //         </form>
        //     </div>

        //     <Footer />
        // </>
        <>
         <TitleBar title="Master Login" />
    <section style={{height:"100vh"}} className="login_wrapper">
      <div className="container-fluid h-100">
        <div className="col-12 h-100">
          <div className="row h-100">
            
            <div className="sign_from_main">
              <div className="col-12">
                <div className="sign_form" style={{alignItems:"center",minHeight:"400px",padding:"20px"}}>
                  <form className="sign_form_top" onSubmit={handleSubmit}>

                    <div className="sign_form_content">

                      <span>Admin Login</span>
                      <h3 className="mt-2">Login to you DoTask Account</h3>
                      <div className="login-input-wrap">
                        <input
                          className="login-input"
                          type="email"
                          placeholder="Email "
                          value={loginInfo.email}
                          onChange={handleChange}
                          name="email"
                          required
                        />
                      </div>
                      <div className="login-input-wrap">
                        <div className="input-logo">
                          <input
                            className="login-input"
                            type={view ? "text" : "password"}
                            placeholder="Password"
                            required
                            onChange={handleChange}
                            value={loginInfo.password}
                            name="password"
                          />
                          <span
                            onMouseUp={() => setView(false)}
                            onMouseLeave={() => setView(false)}
                            onMouseDown={() => setView(true)}
                            className="inp-icon"
                          >
                            <IoEyeOffOutline />
                          </span>
                        </div>
                      </div>
                      <div className="sign-input-wrap">
                        <button type="submit" className="login-btn btn ">Login {loader ? <FaSpinner className="spin ms-1" /> : null}</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section></>
    )
}

export default MasterLogin