import React, { useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
// import { onUpdateCta } from '../../actions/contentAction';
import { useDispatch } from 'react-redux';

const SummerNote = ({ state, setState, editing }) => {
    const dispatch = useDispatch()

    const handleChange = (val) => {

        setState({
            ...state,
            description: val
        })

    }

    const editorConfiguration = {
        toolbar: ['heading', '|', 'fontSize', 'bold', 'italic', '|', 'fontColor', 'fontBackgroundColor', '|', 'undo', 'redo']
    };


    return (
        <CKEditor
            onReady={editor => {
                editor.ui.getEditableElement().parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                );

                // this.editor = editor;
            }}
            onError={(error, { willEditorRestart }) => {
                if (willEditorRestart) {
                    this.editor.ui.view.toolbar.element.remove();
                }
            }}
            onChange={(event, editor) => {
                const data = editor.getData();

                handleChange(data)
            }}
            editor={DecoupledEditor}
            data={state.description}
            config={editorConfiguration}
            disabled={!editing}
        />

    )
}

export default SummerNote