import axios from "axios";
import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";
import { baseURL } from "../../Global/Global";



export const UpdateUpiVerify = (data, setLoader) => (dispatch, getState) => {

    commonAxios("update-upi", data, dispatch,getState().auth.token)
        .then((res) => {

            if (res.status) {
               
          dispatch({ type: 'LOAD_USER_DATA', payload: res.data });
               dispatch(setAlert(res.msg, "success"));
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false)
        }).catch((err) => {

            setLoader(false)
        })

   
}
export const ADDBankDetail = (data1, setLoader,handleClose) => (dispatch, getState) => {

    commonAxios("add-bank-details", data1, dispatch,getState().auth.token)
        .then((res) => {

            if (res.status) {
               
          dispatch({ type: 'ADD_Bank_detail', payload: res.data });
          handleClose()
                dispatch(setAlert(res.msg, "success"));
               
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false)
            handleClose()
        }).catch((err) => {

            setLoader(false)
        })

    
}
export const EditBankDetail = (data1, setLoader,handleClose1) => (dispatch, getState) => {

    commonAxios("update-bank-details", data1, dispatch,getState().auth.token)
        .then((res) => {

            if (res.status) {
               
          dispatch({ type: 'Edit_Bank_detail', payload: res.data });
              handleClose1()

                dispatch(setAlert(res.msg, "success"));
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false)
        }).catch((err) => {

            setLoader(false)
        })
}
export const DeleteBankDetail = (data1, setLoader) => (dispatch, getState) => {

    commonAxios("delete-bank-details", data1, dispatch,getState().auth.token)
        .then((res) => {

            if (res.status) {
                 dispatch({ type: 'delete_Bank_detail', payload: res.data });
                dispatch(setAlert(res.msg, "success"));
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false)
        }).catch((err) => {

            setLoader(false)
        })
}
export const FetchBankDetail = (data, setLoader) => (dispatch, getState) => {

    commonAxios("fetch-bank-details", {}, dispatch,getState().auth.token)
        .then((res) => {

            if (res.status) {
                 dispatch({ type:'Fetch_Bank_detail', payload: res.data });
               } else {
                dispatch(setAlert(res.msg, "danger"));
            }
           
        }).catch((err) => {
         console.log(err)
           
        })
}