import React, { useState } from 'react';
import { BiSolidBank } from "react-icons/bi";
import { GiWallet } from "react-icons/gi";
import { IoIosArrowForward } from "react-icons/io";
import { IoMdLock } from "react-icons/io";
import { PiContactlessPaymentFill } from "react-icons/pi";
import SidePanelTaskCreator from '../../Common/SidePanelTaskCreator';
import TitleBar from '../../Common/TitleBar';
import DashboardHeader from '../../Common/DashboardHeader';
import DashboardFooter from '../../Common/DashboardFooter';


const CreateAmount = () => {
  const [isActive, setIsActive] = useState(true);
  const toggleClass = () => {
      setIsActive(!isActive);
  }
  return (
    <>
      <TitleBar title="Dashboard" />
      <div className="dashboard creator_dashboard  container-fluid overflow-hidden">
        <div className="row">
          <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>
            <SidePanelTaskCreator />
          </div>
          <div className={` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
                        <div>
                            <div className="row">
                                <div className="col-12 ps-0 pe-3">
                                    <div className="dashboard-in">
                                        <DashboardHeader toggleSidebar={toggleClass} title="Amount Payment" />

                                        <div>
                                        <div className={` p-0 ${isActive ? "col-lg-12 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
            <div className='d-flex create-payment p-5 align-items-center justify-content-center' style={{ backgroundColor: "#c7ccd647" }}>
              <div className='container'>
                <div className='row justify-content-center'>
                  <div className='col-lg-12'>
                    <div className='create-payment-bg'>
                      <div className='row align-items-center justify-content-center'>
                        <div className='col-lg-7'>
                          <div className='position-absolute d-flex align-items-center gap-1 payment-logo' style={{ top: "28px" }}><PiContactlessPaymentFill style={{ fontSize: "50px", color: "#3532ea" }} />
                            <span className='fw-bold fs-5 mb-0'>Payment</span>
                          </div>
                          <form action="" className='mb-4'>
                            <span style={{ fontSize: "18px" }} className='payment-heading mb-4 d-block'>Customer Information</span>
                            <div className='inner-form mb-4'>
                              <div className='user-name common-border mb-3'>Udit Sharma</div>
                              <div className='row'>
                                <div className='col-lg-6'>
                                  <p className='common-border mb-3 pb-2 fw-semibold'>+55489653257</p>
                                </div>
                                <div className='col-lg-6'>
                                  <p className='common-border mb-3 pb-2 fw-semibold'>Udit@example.com</p>
                                </div>
                                <div className='col-lg-12'>
                                  <div className='user-name common-border mb-3'>India</div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-lg-6'>
                                  <p className='common-border mb-3 pb-2 fw-semibold'>Japiur</p>
                                </div>
                                <div className='col-lg-6'>
                                  <p className='common-border mb-3 pb-2 fw-semibold'>002335</p>
                                </div>
                                <span className='fw-semibold'>433 Old Gate Ln, MIlford</span>
                              </div>
                            </div>

                            <div className='mb-4'>
                              <span className='mb-3 fw-semibold d-block' style={{ color: "rgba(28, 28, 28, 0.60)" }}>Payment Method</span>
                              <div className='row'>
                                <div className='col-lg-6 mb-3'>
                                  <div className='d-flex align-items-center gap-3 fs-6 payment-card'>
                                    <BiSolidBank
                                      style={{ fontSize: "18px" }} />
                                    <span className='payment-heading'>e-Transfer</span>
                                  </div>
                                </div>
                                <div className='col-lg-6 mb-3'>
                                  <div className='d-flex align-items-center gap-3 fs-6 payment-card'>
                                    <GiWallet
                                      style={{ fontSize: "18px" }} />
                                    <span className='payment-heading'>Online</span>
                                  </div>
                                </div>

                              </div>
                            </div>
                            <div class="add_new_task"><button class="theme-btn w-100 mt-2" style={{ padding: "10px 20px", fontSize: "16px", gap: "5px", color: "#fff" }} href="/create/task">Make payment <IoIosArrowForward style={{ fontSize: "15px", marginTop: "3px" }} /></button>
                            </div>
                          </form>
                        </div>
                        <div className='col-lg-5'>
                          <div className='payment-description'>
                            <div className='d-flex align-items-center pb-5 flex-column gap-2 border-bottom'>
                              <span className='payment-heading mb-0 d-block'>Total Amount</span>
                              <div className='payment-inner-head' style={{ color: "#3532ea", fontSize: "40px" }}>
                                ₹160. <span style={{ color: "#acabeb" }}>76</span>
                              </div>
                              <div className='d-flex align-items-center gap-1'>
                                <IoMdLock
                                  style={{ fontSize: "16px", color: "#079a5d", marginTop: "-2px" }} />
                                <small>Secure Payment</small>
                              </div>
                            </div>
                            <div className='mt-5'>
                              <span className='mb-2 d-block' style={{ fontWeight: "500" }}>Order Summary</span>
                              <div className='d-flex flex-column gap-1 mb-4 order-summary'>
                                <div className='d-flex justify-content-between mb-2'>
                                  <span>Nike Dunk High Retro</span>
                                  <span>₹149.00</span>
                                </div>
                                <span className='text-secondary' style={{ fontWeight: "500" }}>No. of submission: 7</span>
                              </div>
                              <div className='d-flex flex-column gap-1 mb-3 py-0 order-summary'>

                                <div className='d-flex justify-content-between pb-2'>
                                  <span style={{ fontWeight: "500" }} className='text-secondary'>Shipping</span>
                                  <span>₹149.00</span>
                                </div>
                                <div className='d-flex justify-content-between mb-4'>
                                  <span style={{ fontWeight: "500" }} className='text-secondary'>Subtotal</span>
                                  <span>₹149.00</span>
                                </div>
                              </div>
                              <div className='d-flex flex-column gap-1 pt-1 order-summary border-0'>
                                <div className='d-flex justify-content-between'>
                                  <span style={{ fontSize: "17px" }}>Total</span>
                                  <span style={{ fontSize: "20px", color: "#4340ea" }}>₹160.76</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
                                        </div>
                                        <DashboardFooter />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
        </div>
      </div>
    </>

  );
}

export default CreateAmount;
